<template>
  <Breadcrumbs main="New Email" mains="New Email" title="New Email" />
  <div class="container-fluid">
    <div class="email-wrap email-main-wrapper">
      <div class="row">
        <div class="col-xxl-9 col-xl-9 box-col-9">
          <div class="email-right-aside">
            <div class="user-footer">
              <div class="toolbar-box mb-2">
                <div class="row mb-1">
                  <div class="col-sm-12 px-0 py-0">
                    <div class="card-wrapper py-0 d-flex gap-2">
                      <label> From </label>

                      <button
                        type="button"
                        class="btn btn-sm"
                        :class="
                          send_from_email == 'martin@easyreg.ca'
                            ? 'btn-primary text-light'
                            : 'btn-light text-dark'
                        "
                        @click.stop="pickSendFromEmail('martin@easyreg.ca')"
                      >
                        martin@easyreg.ca
                      </button>

                      <button
                        type="button"
                        class="btn btn-sm"
                        :class="
                          send_from_email == 'help@easyreg.ca'
                            ? 'btn-primary text-light'
                            : 'btn-light text-dark'
                        "
                        @click.stop="pickSendFromEmail('help@easyreg.ca')"
                      >
                        help@easyreg.ca
                      </button>
                    </div>
                  </div>
                </div>
                <div class="gap-2 needs-validation">
                  <div class="row mb-1">
                    <div class="col-sm-12 px-0 py-0">
                      <div class="card-wrapper py-0 d-flex gap-2 position-relative">
                        <label> To </label>
                        <VueMultiselect
                          v-model="send_to_emails"
                          :options="to_searched_result"
                          :multiple="true"
                          :taggable="true"
                          :loading="isLoading"
                          :searchable="true"
                          @tag="addNewToEmail($event, 'to')"
                          @search-change="search"
                          placeholder="Type to search by email, firstname, lastname"
                          label="email"
                          track-by="code"
                          @update:modelValue="handleChangeTo"
                        >
                          <template #option="props">
                            <template v-if="props.option">
                              <div class="option__desc">
                                <template v-if="props.option.type == 'Attendee'">
                                  <span class="option__title"
                                    >{{ props.option.type }} -
                                    {{ props.option.firstname }}
                                    {{ props.option.lastname }}
                                    {{ `<${props.option.email}>` }}
                                    {{ props.option.event_name }}
                                    {{ props.option.event_date }}
                                  </span>
                                </template>
                                <template v-if="props.option.type == 'User'">
                                  <span class="option__title">
                                    Client -
                                    {{ props.option.client_abbreviation }} -
                                    {{ props.option.client_name }} -
                                    {{ props.option.firstname }}
                                    {{ props.option.lastname }}
                                    {{ `<${props.option.email}>` }}
                                  </span>
                                </template>
                              </div>
                            </template>
                          </template>
                          <template #noResult>
                            Oops! No elements found. Consider changing the search query.
                          </template>
                        </VueMultiselect>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="gap-2 needs-validation">
                  <div class="row mb-1">
                    <div class="col-sm-12 px-0 py-0">
                      <div class="card-wrapper py-0 d-flex gap-2 position-relative">
                        <label> CC </label>
                        <VueMultiselect
                          v-model="send_cc_emails"
                          :options="cc_searched_result"
                          :loading="isCCLoading"
                          :multiple="true"
                          :taggable="true"
                          :searchable="true"
                          @tag="addNewToEmail($event, 'cc')"
                          @search-change="searchCC"
                          placeholder="Type to search by email, firstname, lastname"
                          label="email"
                          track-by="code"
                          @update:modelValue="handleChangeCC"
                        >
                          <template #option="props">
                            <template v-if="props.option">
                              <div class="option__desc">
                                <template v-if="props.option.type == 'Attendee'">
                                  <span class="option__title"
                                    >{{ props.option.type }} -
                                    {{ props.option.firstname }}
                                    {{ props.option.lastname }}
                                    {{ `<${props.option.email}>` }}
                                    {{ props.option.event_name }}
                                    {{ props.option.event_date }}
                                  </span>
                                </template>
                                <template v-if="props.option.type == 'User'">
                                  <span class="option__title">
                                    Client -
                                    {{ props.option.client_abbreviation }} -
                                    {{ props.option.client_name }} -
                                    {{ props.option.firstname }}
                                    {{ props.option.lastname }}
                                    {{ `<${props.option.email}>` }}
                                  </span>
                                </template>
                              </div>
                            </template>
                          </template>
                          <template #noResult>
                            Oops! No elements found. Consider changing the search query.
                          </template>
                        </VueMultiselect>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="gap-2 needs-validation">
                  <div class="row mb-1">
                    <div class="col-sm-12 px-0 py-0">
                      <div class="card-wrapper py-0 d-flex gap-2 position-relative">
                        <label> BCC </label>
                        <VueMultiselect
                          v-model="send_bcc_emails"
                          :options="bcc_searched_result"
                          :loading="isBCCLoading"
                          :multiple="true"
                          :taggable="true"
                          :searchable="true"
                          @tag="addNewToEmail($event, 'bcc')"
                          @search-change="searchBCC"
                          placeholder="Type to search by email, firstname, lastname"
                          label="email"
                          track-by="code"
                          @update:modelValue="handleChangeBCC"
                        >
                          <template #option="props">
                            <template v-if="props.option">
                              <div class="option__desc">
                                <template v-if="props.option.type == 'Attendee'">
                                  <span class="option__title"
                                    >{{ props.option.type }} -
                                    {{ props.option.firstname }}
                                    {{ props.option.lastname }}
                                    {{ `<${props.option.email}>` }}
                                    {{ props.option.event_name }}
                                    {{ props.option.event_date }}
                                  </span>
                                </template>
                                <template v-if="props.option.type == 'User'">
                                  <span class="option__title">
                                    Client -
                                    {{ props.option.client_abbreviation }} -
                                    {{ props.option.client_name }} -
                                    {{ props.option.firstname }}
                                    {{ props.option.lastname }}
                                    {{ `<${props.option.email}>` }}
                                  </span>
                                </template>
                              </div>
                            </template>
                          </template>
                          <template #noResult>
                            Oops! No elements found. Consider changing the search query.
                          </template>
                        </VueMultiselect>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mb-1 needs-validation">
                  <div class="col-sm-12 px-0 py-0">
                    <div class="card-wrapper py-0 d-flex gap-2 position-relative">
                      <label class="col-form-label">Subject</label>
                      <input
                        class="form-control"
                        id="composeFrom"
                        type="text"
                        v-model="to_subject"
                        @input="debouncedInput"
                        v-bind:class="
                          formSubmitted
                            ? to_subject == ''
                              ? 'is-invalid'
                              : 'is-valid'
                            : ''
                        "
                      />
                      <div
                        class="invalid-tooltip"
                        v-if="formSubmitted && to_subject == ''"
                      >
                        Subject is empty!
                      </div>
                    </div>
                  </div>
                </div>
                <div class="editor-wrapper">
                  <div id="editor-container" class="w-full"></div>
                </div>
              </div>
              <div class="d-flex justify-content-between align-items-start px-2">
                <div class="mt-2 pt-2">
                  <div class="d-flex flex-column attachment-wrapper gap-2">
                    <template
                      v-for="(send_attachment, send_attachment_index) in send_attachments"
                      :key="`attachment-index-${send_attachment_index}`"
                    >
                      <div class="d-flex justify-content-between gap-2 attachment-item">
                        <div class="d-flex gap-1">
                          <span class="filename">{{ send_attachment.name }}</span>
                          <span>({{ $filters.filesize(send_attachment.size) }})</span>
                        </div>

                        <button
                          class="btn-close btn-sm"
                          @click.stop="handleRemoveAttachment(send_attachment)"
                        ></button>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="send-btn d-flex gap-2 justify-content-end mb-4">
                  <button
                    class="btn btn-secondary d-flex gap-2"
                    type="button"
                    @click="handleAttachFiles"
                  >
                    Attach files<i class="fa-solid fa-paperclip"></i>
                  </button>
                  <button
                    class="btn btn-primary d-flex gap-2"
                    type="button"
                    @click="handleSend"
                  >
                    Send<i class="fa-solid fa-paper-plane"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-3 col-xl-3 box-col-3 p-0">
          <div class="email-right-aside p-2">
            <div class="p-2 pb-0 article-wrapper">
              <span class="text">Search Articles</span>
            </div>
            <div class="p-2 search-wrapper">
              <div class="d-flex align-items-center gap-2">
                <i class="search-bg iconly-Search icli"></i>
                <input
                  class="demo-input py-0 Typeahead-input form-control-plaintext w-100"
                  type="text"
                  placeholder="Search for keywords or title"
                  title=""
                  @keyup="searchArticles"
                  v-model="keyword_for_article"
                />
                <template v-if="keyword_for_article != ''">
                  <span
                    @click.stop="keyword_for_article = ''"
                    class="cursor-pointer close-btn d-flex align-items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="3"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </span>
                </template>
              </div>
            </div>

            <template v-if="searched_articles.length > 0">
              <div class="mt-1 p-2">
                <span class="badge badge-success">
                  <h4>Articles</h4>
                </span>
              </div>
              <div
                class="card email-body email-read show gap-2 article-wrapper overflow-wrapper"
              >
                <template
                  v-for="(searched_article, index) in searched_articles"
                  :key="`searched-article-${index}`"
                >
                  <div
                    class="flex flex-column align-items-start p-2 article-searched-wrapper"
                  >
                    <div class="d-flex flex-column">
                      <div class="d-flex gap-2 justify-between-content article-headline">
                        <span class="assigned_name flex-1">{{
                          searched_article.article_headline
                        }}</span>
                        <button
                          type="button"
                          @click.stop="handleInsert(searched_article.article_id)"
                          class="btn btn-md btn-light text-dark btn-receipt mb-1"
                        >
                          Insert
                        </button>
                      </div>
                    </div>
                    <div>
                      <span class="article-content d-flex flex-column gap-1">
                        <div v-html="searched_article.article_text"></div>
                      </span>
                    </div>
                  </div>
                </template>
              </div>
            </template>

            <template v-if="searched_urls.length > 0">
              <div class="mt-1 p-2">
                <span class="badge badge-success">
                  <h4>Tiny URLs</h4>
                </span>
              </div>
              <div
                class="card email-body email-read show gap-2 article-wrapper overflow-url-wrapper"
              >
                <template
                  v-for="(searched_url, index) in searched_urls"
                  :key="`searched-url-${index}`"
                >
                  <div
                    class="flex flex-column align-items-start p-2 article-searched-wrapper"
                  >
                    <div class="d-flex flex-column">
                      <div class="d-flex gap-2 justify-between-content article-headline">
                        <span class="assigned_name flex-1">{{
                          searched_url.url_keywords
                        }}</span>
                        <button
                          type="button"
                          @click.stop="handleInsertURL(searched_url.url_id)"
                          class="btn btn-md btn-light text-dark btn-receipt mb-1"
                        >
                          Insert
                        </button>
                      </div>
                    </div>
                    <div>
                      <span class="article-content d-flex flex-column gap-1">
                        <div v-html="searched_url.url_short"></div>
                      </span>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <input type="file" accept="*" hidden ref="uploadRef" @change="uploadFile" />
  </div>
</template>
<style lang="scss" scoped>
.email-item {
  border: 1px solid #308e87;
  border-radius: 18px;
  background: #308e87;
  color: #fff;
  padding: 3px 8px;
  font-size: 13px;
}
.searched-wrapper {
  background: #ececec;
  .text {
    color: #7bcc00;
    font-weight: 800;
    font-size: 18px;
  }
}
.email-right-aside {
  background: #fff;
}
.search-wrapper {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 20px;
  .close-btn {
    svg {
      width: 18px;
      height: 18px;
    }
  }
}
.overflow-wrapper {
  height: 350px;
  padding: 2px;
  overflow-y: scroll;
}
.overflow-url-wrapper {
  height: 250px;
  padding: 2px;
  overflow-y: scroll;
}
.article-wrapper {
  background: none;
  .text {
    color: #7bcc00;
    font-weight: 800;
    font-size: 18px;
  }
  .assigned_name {
    font-size: 13px;
    font-weight: 800;
  }
}
.article-searched-wrapper {
  border-bottom: 3px solid #7bcc00;
  .article-headline {
    width: 100%;
    border-bottom: 1px solid #7bcc00;
    justify-content: space-between !important;
    align-items: start;
    .assigned_name {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 13px;
      font-weight: 800;
    }
  }
  .btn-receipt {
    border: 1px solid #7bcc00;
    float: right;
  }
  .article-content {
    height: 37px;
    font-weight: 300;
    letter-spacing: 0.3;
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      color: var(--font-color);
      font-weight: 400;
      line-height: 1.9;
    }
  }
}
.email-right-aside {
  background: #fff;
}
.assigned-wrapper {
  border-bottom: 2px solid #7bcc00;
  .assigned_event {
    font-weight: 600;
  }
  .assigned_name {
    font-size: 17px;
    font-weight: 800;
  }
  .btn-receipt {
    border: 1px solid #7bcc00;
  }
  .assigned_code {
    font-size: 18px;
    font-weight: 800;
    align-items: end !important;
  }
}
.attachment-wrapper {
  max-width: 530px;
  .attachment-item {
    width: 100%;
    font-weight: 600;
    .filename {
      flex: 1;
      color: blue;
      max-width: 320px;
      display: inline-block;
      overflow: hidden;
      padding: 3px 0;
      text-overflow: ellipsis;
      vertical-align: bottom;
      white-space: nowrap;
    }
    background: #ececec;
    padding: 5px 20px;
  }
}
.btn-reply {
  display: flex;
  border: 1px solid #730000;
  &:hover {
    border: 1px solid #730000;
    font-weight: bold;
  }
  &:focus {
    border: 1px solid #730000;
    font-weight: bold;
  }
  &:before {
    background-image: url(//ssl.gstatic.com/ui/v1/icons/mail/gm3/1x/reply_baseline_nv700_20dp.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    opacity: 1;
    height: 20px;
    width: 20px;
    margin-right: 8px;
    content: "";
  }
}
.btn-reply-all {
  display: flex;
  border: 1px solid #730000;
  &:hover {
    border: 1px solid #730000;
    font-weight: bold;
  }
  &:focus {
    border: 1px solid #730000;
    font-weight: bold;
  }
  &:before {
    background-image: url(//ssl.gstatic.com/ui/v1/icons/mail/gm3/1x/reply_all_baseline_nv700_20dp.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    opacity: 1;
    height: 20px;
    width: 20px;
    margin-right: 8px;
    content: "";
  }
}
.toolbar-box {
  padding: 5px !important;
  // border: 1px solid #730000;
}
.mail-subcontent::v-deep {
  height: 400px;
  iframe {
    html {
      body {
        font-family: sans-serif;
      }
    }
  }
}
#editor-container::v-deep {
  height: 610px !important;
}
.flex-1 {
  flex: 1;
}
.editor-wrapper {
  height: 610px !important;
}
</style>
<script lang="ts" setup>
import VueMultiselect from "vue-multiselect";
import debounce from "lodash.debounce";
import {
  defineAsyncComponent,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  useTemplateRef,
  watch,
} from "vue";
import { useCommonStore } from "@/store/common";
import { storeToRefs } from "pinia";
import {
  GenerateEmailID,
  GetEmailByTemplate,
  GetSearchByKeyword,
  GetSearchSystemByKeyword,
  ReturnDesignJsonByAddTemplate,
  SaveAutoJson,
  SaveDraftEmailValue,
  SendEmailUsingNylas,
} from "@/services/ApiCore";
import Vue3TagsInput from "vue3-tags-input";
import TopolPlugin from "@topol.io/editor";
let searched_articles = ref<any>([]);
let searched_urls = ref<any>([]);
import { onBeforeRouteLeave } from "vue-router";

let keyword_for_article = ref<string>("");
let topolPluginInit = ref<boolean>(false);
let commonStore = useCommonStore();
const hasUnsavedChanges = ref<boolean>(false);
const { template: template } = storeToRefs(commonStore);
watch(
  () => template,
  () => {
    editor_loaded.value = false;
    setTimeout(() => {
      editor_loaded.value = true;
      nextTick(() => {
        if (commonStore.template) {
          initEditor();
        }
      });
    }, 200);
  },
  { deep: true }
);
let customOptions = ref<any>();
const uploadRef = useTemplateRef("uploadRef");
let editor_loaded = ref<boolean>(false);
let send_from_email = ref<any>("martin@easyreg.ca");
let to_subject = ref<string>("");
let send_attachments = ref<any>([]);
let to_searched_result = ref<any>([]);
let cc_searched_result = ref<any>([]);
let bcc_searched_result = ref<any>([]);
let send_to_emails = ref<any>([]);
let send_cc_emails = ref<any>([]);
let send_bcc_emails = ref<any>([]);
let currentJson = ref<string>("");
let currentHtml = ref<string>("");
let formSubmitted = ref<boolean>(false);
let sendingEmail = ref<boolean>(false);
let isLoading = ref<boolean>(false);
let isCCLoading = ref<boolean>(false);
let isBCCLoading = ref<boolean>(false);
let emails_id = ref<string>("");
let vvalue = ref<any>([]);
let autosave_interval = -1;
import { useRoute, useRouter } from "vue-router";
import { GetArticles } from "@/services/ApiArticles";
let router = useRouter();
function loadEditor() {
  if (topolPluginInit.value) {
    TopolPlugin.destroy();
  }
  const TOPOL_OPTIONS = {
    id: "#editor-container",
    // enableAutosaves: true,
    // api: {
    //   // Api endpoints needed for autosaves
    //   AUTOSAVE: `${process.env.apiUrl}/autosave/${emails_id.value}`,
    // },
    // autosaveInterval: 5,
    disableAlerts: true,
    authorize: {
      apiKey: process.env.TOPOL_API_KEY,
      userId: 1,
    },
    apiEndpoint: "---",
    tinyConfig: {
      selector: "textarea", // Your selector here
      paste_data_images: true, // Ensure that pasted images are handled
    },
    light: true,
    defaultTemplateSettings: {
      p: {
        "font-family": "Arial, sans-serif",
        "font-size": "14px",
        "font-weight": "400",
        "font-style": "normal",
        "background-color": "#000000",
        "line-height": 1.3,
        color: "#ffffff",
      },
      h1: {
        "font-family": "Arial, sans-serif",
        "font-size": "24px",
        "font-weight": "700",
        "font-style": "normal",
        "background-color": "#000000",
        marginTop: "30px;",
        marginTop: "20px;",
        color: "#ffffff",
      },
      h2: {
        "font-family": "Arial, sans-serif",
        "font-size": "20px",
        "font-weight": "700",
        "font-style": "normal",
        marginTop: "20px;",
        marginTop: "10px;",
        "background-color": "#000000",
        color: "#ffffff",
      },
      h3: {
        "font-family": "Arial, sans-serif",
        "font-size": "16px",
        "font-weight": "700",
        "font-style": "normal",
        marginTop: "10px;",
        marginTop: "5px;",
        "background-color": "#000000",
        color: "#ffffff",
      },
    },
    premadeBlocks: [
      {
        name: "Online Attendee Site",
        blocks: [
          {
            img:
              "https://2023-easyreg-prod.s3.ca-central-1.amazonaws.com/attachments/online_attendee_site_premade_block.png", // Image url, for best experience use width > 330 px
            name: "Online Attendee Site Block", // Or name if not image available
            // MJML JSON
            definition: [
              {
                tagName: "mj-section",
                attributes: {
                  "full-width": "600px",
                  padding: "10px 0px 10px 0px",
                  "mj-class": "section",
                },
                type: null,
                children: [
                  {
                    tagName: "mj-column",
                    attributes: {
                      width: "100%",
                      "vertical-align": "top",
                    },
                    children: [
                      {
                        tagName: "mj-text",
                        content:
                          '<p>You can access the Online Attendee Site using the following link:<br>###attendee.accesslink###</p>\n          <p style="font-weight: bold; margin-bottom: 10px; margin-top: 10px;">OR</p>\n          <ul>\n              <li>Go to https://online.ourconference.events</li>\n              <li>Enter ###attendee.email### under Email Address</li>\n              <li>Enter ###attendee.code### under Code</li>\n              <li>Click Login</li>\n          </ul>',
                        attributes: {
                          padding: "10px 10px",
                          align: "left",
                          "line-height": 1.3,
                          containerWidth: 600,
                        },
                        uid: "H1lqIiX4lm",
                      },
                    ],
                    uid: "SJ3I0XVx7",
                  },
                ],
                layout: 1,
                backgroundColor: "",
                backgroundImage: "",
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
                uid: "rkqIjQNe7",
              },
            ],
          },
        ],
      },
    ],
    customBlocks: [],
    showUnsavedDialogBeforeExit: false,
    htmlMinified: true,
    callbacks: {
      onInit(json: any, html: any) {
        topolPluginInit.value = true;
        autosave_interval = setInterval(() => {
          TopolPlugin && TopolPlugin.save();
        }, 10000);
      },
      onBlockRemove(blockId: number) {
        console.log("onBlock Remove blockId", blockId);
      },
      onBlockEdit(blockId: number) {
        console.log("onBlock Edit blockId", blockId);
      },
      async onSave(json: any, html: any) {
        currentJson.value = json;
        currentHtml.value = html;
        if (sendingEmail.value) {
          sendingEmail.value = false;
          formSubmitted.value = true;
          try {
            if (send_from_email.value == "") {
              sendingEmail.value = false;
              return;
            }
            if (to_subject.value == "") {
              sendingEmail.value = false;
              return;
            }
            const { result } = await SendEmailUsingNylas(
              emails_id.value,
              send_from_email.value,
              JSON.stringify(send_to_emails.value),
              JSON.stringify(send_cc_emails.value),
              JSON.stringify(send_bcc_emails.value),
              to_subject.value,
              currentHtml.value,
              send_attachments.value,
              [],
              "N",
              "N",
              "N"
            );
            commonStore.toggleSent(result);
            sendingEmail.value = false;
            autosave_interval = setInterval(() => {
              TopolPlugin && TopolPlugin.save();
            }, 10000);
            if (result == "success") {
              router.push("/app/emails/sent");
            } else {
            }
          } catch (e) {
            sendingEmail.value = false;
            autosave_interval = setInterval(() => {
              TopolPlugin && TopolPlugin.save();
            }, 10000);
            console.log("handleSend error", e);
          }
        } else {
          await SaveAutoJson(emails_id.value, JSON.stringify(json));
        }
      },
      onLoaded(json: any, html: any) {
        TopolPlugin.save();
      },
    },
  };
  TopolPlugin.init(TOPOL_OPTIONS);
}
function loadDesign(json: any) {
  if (json) {
    TopolPlugin.load(json);
  }
}
function initEditor() {
  (async () => {
    try {
      loadEditor();
      let template = "BLANK";
      if (commonStore.template) {
        template = commonStore.template;
      }
      const { json, html, subject } = await GetEmailByTemplate(template);
      if (subject != "" && subject != null) {
        to_subject.value = subject;
      }
      currentJson.value = json;
      currentHtml.value = html;
      loadDesign(json);
    } catch (e) {
      console.log("onMounted error", e);
    }
  })();
}
function handleAttachFiles() {
  if (uploadRef.value) {
    uploadRef.value.click();
  }
}
function handleSend() {
  (async () => {
    if (autosave_interval) {
      clearInterval(autosave_interval);
    }
    sendingEmail.value = true;
    TopolPlugin.save();
  })();
}
function generateId(len: any) {
  var arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr).join("");
}
async function loadFile(files: any) {
  const filePromises = Array.from(files).map((file: any) => {
    // Return a promise per file
    return new Promise((resolve, reject) => {
      let response = {};
      const reader = new FileReader();
      reader.onload = async () => {
        try {
          response = {
            handleId: generateId(20),
            pathname: file.name,
            name: file.name,
            size: file.size,
            type: file.type,
            image: null,
            file: file,
            uploaded: "B",
          };
          // Resolve the promise with the response value
          resolve(response);
        } catch (err) {
          reject(err);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  });
  const fileInfos = await Promise.all(filePromises);
  console.log("fileInfos", fileInfos);
  return fileInfos;
}
async function uploadFile(e: any) {
  if (e.target.files.length === 0) return;
  console.log("e.target.files", e.target.files);
  const result = await loadFile(e.target.files);
  console.log("result", result);
  send_attachments.value = [...send_attachments.value, ...result];
}
function handleRemoveAttachment(file: any) {
  send_attachments.value = send_attachments.value.filter(
    (x: any) => x.handleId != file.handleId
  );
}
async function searchArticles(e: any) {
  if (e.which == 13) {
    if (keyword_for_article.value != "") {
      const { rows, urls } = await GetArticles("keyword", keyword_for_article.value, {});
      searched_articles.value = rows;
      searched_urls.value = urls;
    }
    e.preventDefault();
  }
}
function handleInsert(article_id: any) {
  (async () => {
    try {
      const { json } = await ReturnDesignJsonByAddTemplate(
        JSON.stringify(currentJson.value),
        article_id,
        "article"
      );
      loadDesign(json);
    } catch (e) {
      console.log("onMounted error", e);
    }
  })();
}

function handleInsertURL(url_id: any) {
  (async () => {
    try {
      const { json } = await ReturnDesignJsonByAddTemplate(
        JSON.stringify(currentJson.value),
        url_id,
        "url"
      );
      loadDesign(json);
    } catch (e) {
      console.log("onMounted error", e);
    }
  })();
}

const searchBCC = debounce(async (keyword: any) => {
  if (keyword.length < 5) {
    return;
  }
  isBCCLoading.value = false;
  bcc_searched_result.value = await GetSearchByKeyword(keyword);
  isBCCLoading.value = false;
}, 500);
const searchCC = debounce(async (keyword: any) => {
  if (keyword.length < 5) {
    return;
  }
  isCCLoading.value = true;
  cc_searched_result.value = await GetSearchByKeyword(keyword);
  isCCLoading.value = false;
}, 500);
const search = debounce(async (keyword: any) => {
  if (keyword.length < 5) {
    return;
  }
  isLoading.value = true;
  to_searched_result.value = await GetSearchByKeyword(keyword);
  isLoading.value = false;
}, 500);
const addNewToEmail = debounce(async (email: any, type: string) => {
  console.log("string", type);
  const new_tag = {
    firstname: email,
    lastname: "",
    email: email,
  };
  if (type == "to") {
    send_to_emails.value.push(new_tag);
    await SaveDraftEmailValue(
      emails_id.value,
      JSON.stringify(send_to_emails.value),
      "to"
    );
  } else if (type == "cc") {
    send_cc_emails.value.push(new_tag);
    await SaveDraftEmailValue(
      emails_id.value,
      JSON.stringify(send_cc_emails.value),
      "cc"
    );
  } else if (type == "bcc") {
    send_bcc_emails.value.push(new_tag);
    await SaveDraftEmailValue(
      emails_id.value,
      JSON.stringify(send_bcc_emails.value),
      "bcc"
    );
  }
}, 500);
const pickSendFromEmail = debounce(async (email: string) => {
  send_from_email.value = email;
  await SaveDraftEmailValue(emails_id.value, email, "from");
}, 500);
const debouncedInput = debounce(async (event: Event) => {
  const target = event.target as HTMLInputElement;
  to_subject.value = target.value;
  await SaveDraftEmailValue(emails_id.value, to_subject.value, "subject");
}, 500);
onUnmounted(() => {
  if (topolPluginInit.value) {
    TopolPlugin.destroy();
  }
});
const handleChangeTo = debounce(async () => {
  if (send_to_emails.value.length > 0) {
    let send_to_emails_arr = send_to_emails.value.map((x) => {
      return {
        firstname: x.firstname,
        lastname: x.lastname,
        email: x.email,
      };
    });
    await SaveDraftEmailValue(emails_id.value, JSON.stringify(send_to_emails_arr), "to");
  }
}, 500);
const handleChangeCC = debounce(async () => {
  if (send_cc_emails.value.length > 0) {
    let send_cc_emails_arr = send_cc_emails.value.map((x) => {
      return {
        firstname: x.firstname,
        lastname: x.lastname,
        email: x.email,
      };
    });
    await SaveDraftEmailValue(emails_id.value, JSON.stringify(send_cc_emails_arr), "cc");
  }
}, 500);
const handleChangeBCC = debounce(async () => {
  if (send_bcc_emails.value.length > 0) {
    let send_bcc_emails_arr = send_bcc_emails.value.map((x) => {
      return {
        firstname: x.firstname,
        lastname: x.lastname,
        email: x.email,
      };
    });
    await SaveDraftEmailValue(
      emails_id.value,
      JSON.stringify(send_bcc_emails_arr),
      "bcc"
    );
  }
}, 500);
// onBeforeRouteLeave((to, from, next) => {
//   const answer = window.confirm("You have unsaved changes. Do you really want to leave?");
//   if (!answer) {
//     return next(false); // Block navigation
//   }
//   next(); // Allow navigation
// });
onMounted(async () => {
  emails_id.value = await GenerateEmailID();
  pickSendFromEmail("martin@easyreg.ca");
  sendingEmail.value = false;
  send_to_emails.value = [];
  send_cc_emails.value = [];
  send_bcc_emails.value = [];
  formSubmitted.value = false;
  keyword_for_article.value = "";
  searched_articles.value = [];
  send_from_email.value = "martin@easyreg.ca";
  editor_loaded.value = false;
  setTimeout(() => {
    editor_loaded.value = true;
    nextTick(() => {
      initEditor();
    });
  }, 200);
});
</script>
