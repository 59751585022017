interface MenuItem {
    headTitle1?: string;
    headTitle2?: string;
    title?: string;
    icon?: string;
    icon1?: string;
    type: string;
    badgeType?: string;
    active?: boolean;
    isPinned?: boolean;
    path?: string;
    bagde?: string,
    children?: MenuItem[];
    bookmark?: boolean;
}

export const menu: MenuItem[] = [
    {
        title: "Dashboard",
        path: "/dashboards/dashboard_default",
        icon: "Home-dashboard",
        icon1: "fill-home",
        type: "link",
        badgeType: "badge",
        active: false,
    },
    {
        title: "AWS Dashboard",
        path: "/dashboards/aws_dashboard_default",
        icon: "Home-dashboard",
        icon1: "fill-home",
        type: "link",
        badgeType: "badge",
        active: false,
    },
    {
        path: "/app/emails/review",
        icon: "Message",
        icon1: "fill-email",
        title: "Emails",
        active: false,
        type: "link"
    },
    {
        path: "/app/sms",
        icon: "Message",
        icon1: "fill-sms",
        title: "SMS",
        active: false,
        type: "link"
    },
    {
        path: "/app/todos/list",
        icon: "Wallet",
        icon1: "fill-money",
        title: "To Dos",
        active: false,
        type: "link"
    },
    {
        path: "/app/disputes/list",
        icon: "Wallet",
        icon1: "fill-money",
        title: "Disputes",
        active: false,
        type: "link"
    },
    {
        path: "/app/knowledgebase",
        icon: "Message",
        icon1: "fill-email",
        title: "Knowledgebase",
        active: false,
        type: "link"
    },
    {
        title: "Administration",
        icon: "Setting",
        icon1: "fill-project",
        type: "sub",
        badgeType: "light-info",
        isPinned: false,
        active: false,
        children: [
            {
                path: "/administration/manage-articles/list",
                title: "Articles",
                type: "link"
            },
            {
                path: "/administration/manage-tinyurls/list",
                title: "Tiny URLs",
                type: "link"
            },
            {
                path: "/administration/manage-rule/list",
                title: "Rules",
                type: "link"
            },
            {
                path: "/administration/templates-easyreg/list",
                title: "Templates",
                type: "link"
            },
            {
                path: "/administration/manage-category/list",
                title: "Categories",
                type: "link"
            },
            {
                path: "/administration/manage-assignment/list",
                title: "Assignments",
                type: "link"
            }            
        ]
    },
]
