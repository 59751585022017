import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import BodyView from "@/layout/BodyView.vue"
import AuthView from "@/components/common/block/auth/AuthView.vue"
import LoginPage from "@/components/common/block/auth/LoginPage.vue"
import IndexDefault from "@/pages/dashboards/IndexDefault.vue"
import EC2Default from "@/pages/dashboards/EC2Default.vue"
import IndexKnowledgebase from "@/pages/knowledgebase/IndexKnowledgebase.vue"
import IndexEmails from "@/pages/emails/IndexEmails.vue"
import IndexSMS from "@/pages/sms/IndexSMS.vue"
import IndexDisputes from "@/pages/disputes/IndexDisputes.vue"
import ViewDisputes from "@/pages/disputes/ViewDisputes.vue"
import IndexToDo from "@/pages/todo/IndexToDo.vue"
import EmailsDetail from '@/components/theme/emails/EmailsDetail.vue'
import EmailsDraftDetail from '@/components/theme/emails/EmailsDraftDetail.vue'
import IndexTemplates from '@/pages/administration/templates/IndexTemplates.vue'
import IndexRules from '@/pages/administration/rules/IndexRules.vue'
import NewEmails from '@/pages/emails/NewEmails.vue'
import EditTemplates from '@/pages/administration/templates/EditTemplates.vue'
import IndexCategories from '@/pages/administration/categories/IndexCategories.vue'
import IndexArticles from '@/pages/administration/articles/IndexArticles.vue'
import IndexAssignments from '@/pages/administration/assignments/IndexAssignments.vue'
import SaveArticle from '@/pages/administration/articles/SaveArticle.vue'
import IndexTinyURLs from '@/pages/administration/tinyurls/IndexTinyURLs.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "/dashboards/dashboard_default"
  },
  {
    path: '/',
    name: 'home',
    component: BodyView,
    meta: {
      title: 'easyREG CRM',
    },
    children: [
      {
        path: '',
        name: 'defaultRoot',
        component: IndexDefault,
        meta: {
          title: 'easyREG CRM',
        }
      }
    ]
  },
  {
    path: "/auth",
    component: AuthView,
    children: [
      {
        path: "login",
        name: "login",
        component: LoginPage,
        meta: {
          title: 'easyREG CRM',
        }
      }
    ]
  },
  {
    path: "/dashboards",
    component: BodyView,
    children: [
      {
        path: "dashboard_default",
        name: "default",
        component: IndexDefault,
        meta: {
          title: 'Dashboard',
        }
      },
      {
        path: "aws_dashboard_default",
        name: "aws_dashboard_default",
        component: EC2Default,
        meta: {
          title: 'AWS Dashboard',
        }
      },
    ]
  },
  {
    path: "/app",
    component: BodyView,
    children: [
      {
        path: "new-email",
        name: "new-email",
        component: NewEmails,
        meta: {
          title: 'New Email',
        },
      },
      {
        path: "disputes/list",
        name: "disputes.list",
        component: IndexDisputes,
        meta: {
          title: 'Disputes',
        },
      },
      {
        path: "disputes/view/:dispute_id",
        name: "disputes.view",
        component: ViewDisputes,
        meta: {
          title: 'Disputes',
        },
      },
      {
        path: "emails",
        name: "emails",
        component: IndexEmails,
        meta: {
          title: 'Emails',
        },
      },
      {
        path: "sms",
        name: "sms",
        component: IndexSMS,
        meta: {
          title: 'SMS',
        },
      },
      {
        path: "emails/:tab",
        name: "emails_tab",
        component: IndexEmails,
        meta: {
          title: 'Emails',
        },
      },
      {
        path: "emails/:tab/:emails_id",
        name: "emails_tab_view",
        component: EmailsDetail,
        meta: {
          title: 'Emails',
        },
      },
      {
        path: "emails/draft/:emails_id",
        name: "emails_draft_tab_view",
        component: EmailsDraftDetail,
        meta: {
          title: 'Emails',
        },
      },
      {
        path: "todos/list",
        name: "todos.list",
        component: IndexToDo,
        meta: {
          title: 'To Dos',
        }
      },
      {
        path: "knowledgebase",
        name: "knowledgebase",
        component: IndexKnowledgebase,
        meta: {
          title: 'Knowledgebase',
        }
      },
    ]
  },
  {
    path: "/administration",
    component: BodyView,
    children: [
      {
        path: "manage-articles/edit/:article_id",
        name: "manage-articles-edit",
        component: SaveArticle,
        meta: {
          title: 'Emails',
        },
      },
      {
        path: "manage-articles",
        name: "manage-articles",
        component: IndexArticles,
        meta: {
          title: 'Articles',
        },
      },
      {
        path: "manage-articles/list",
        name: "manage-articles-list",
        component: IndexArticles,
        meta: {
          title: 'Emails',
        },
      },
      {
        path: "templates-easyreg",
        name: "templates-easyreg",
        component: IndexTemplates,
        meta: {
          title: 'Emails',
        },
      },
      {
        path: "templates-easyreg/list",
        name: "templates-easyreg-list",
        component: IndexTemplates,
        meta: {
          title: 'Emails',
        },
      },
      {
        path: "templates-easyreg/edit/:template_id",
        name: "templates-easyreg-edit",
        component: EditTemplates,
        meta: {
          title: 'Emails',
        },
      },
      {
        path: "manage-rule",
        name: "manage-rule",
        component: IndexRules,
        meta: {
          title: 'Emails',
        },
      },
      {
        path: "manage-rule/list",
        name: "manage-rule-list",
        component: IndexRules,
        meta: {
          title: 'Emails',
        },
      },
      {
        path: "manage-category",
        name: "manage-category",
        component: IndexCategories,
        meta: {
          title: 'Emails',
        },
      },
      {
        path: "manage-category/list",
        name: "manage-categories-list",
        component: IndexCategories,
        meta: {
          title: 'Emails',
        },
      },
      {
        path: "manage-assignment",
        name: "manage-assignment",
        component: IndexAssignments,
        meta: {
          title: 'Emails',
        },
      },
      {
        path: "manage-assignment/list",
        name: "manage-assignment-list",
        component: IndexAssignments,
        meta: {
          title: 'Emails',
        },
      },
      {
        path: "manage-tinyurls",
        name: "manage-tinyurls",
        component: IndexTinyURLs,
        meta: {
          title: 'Articles',
        },
      },
      {
        path: "manage-tinyurls/list",
        name: "manage-tinyurls-list",
        component: IndexTinyURLs,
        meta: {
          title: 'Emails',
        },
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  // if (from.path === "/app/new-email") {
  //   const answer = window.confirm("You have unsaved changes. Leave anyway?");
  //   if (!answer) {
  //     return next(false); // Prevent navigation
  //   }
  // }
  // next(); // Allow navigation
  window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrolls smoothly to top

  if (typeof (to.meta.title) === 'string') {
    document.title = to.meta.title;
  }
  const path = ['/auth/login', '/auth/register'];
  if (path.includes(to.path) || localStorage.getItem('easyREGCRMToken')) {
    return next();
  }
  next('/auth/login');
});

export default router
