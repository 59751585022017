import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, withModifiers as _withModifiers, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, isRef as _isRef, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "email-wrap email-main-wrapper" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-xxl-9 col-xl-9 box-col-9" }
const _hoisted_5 = { class: "email-right-aside" }
const _hoisted_6 = { class: "user-footer" }
const _hoisted_7 = { class: "toolbar-box mb-2" }
const _hoisted_8 = { class: "row mb-1" }
const _hoisted_9 = { class: "col-sm-12 px-0 py-0" }
const _hoisted_10 = { class: "card-wrapper py-0 d-flex gap-2" }
const _hoisted_11 = { class: "gap-2 needs-validation" }
const _hoisted_12 = { class: "row mb-1" }
const _hoisted_13 = { class: "col-sm-12 px-0 py-0" }
const _hoisted_14 = { class: "card-wrapper py-0 d-flex gap-2 position-relative" }
const _hoisted_15 = {
  key: 0,
  class: "option__desc"
}
const _hoisted_16 = {
  key: 0,
  class: "option__title"
}
const _hoisted_17 = {
  key: 1,
  class: "option__title"
}
const _hoisted_18 = { class: "gap-2 needs-validation" }
const _hoisted_19 = { class: "row mb-1" }
const _hoisted_20 = { class: "col-sm-12 px-0 py-0" }
const _hoisted_21 = { class: "card-wrapper py-0 d-flex gap-2 position-relative" }
const _hoisted_22 = {
  key: 0,
  class: "option__desc"
}
const _hoisted_23 = {
  key: 0,
  class: "option__title"
}
const _hoisted_24 = {
  key: 1,
  class: "option__title"
}
const _hoisted_25 = { class: "gap-2 needs-validation" }
const _hoisted_26 = { class: "row mb-1" }
const _hoisted_27 = { class: "col-sm-12 px-0 py-0" }
const _hoisted_28 = { class: "card-wrapper py-0 d-flex gap-2 position-relative" }
const _hoisted_29 = {
  key: 0,
  class: "option__desc"
}
const _hoisted_30 = {
  key: 0,
  class: "option__title"
}
const _hoisted_31 = {
  key: 1,
  class: "option__title"
}
const _hoisted_32 = { class: "row mb-1 needs-validation" }
const _hoisted_33 = { class: "col-sm-12 px-0 py-0" }
const _hoisted_34 = { class: "card-wrapper py-0 d-flex gap-2 position-relative" }
const _hoisted_35 = {
  key: 0,
  class: "invalid-tooltip"
}
const _hoisted_36 = { class: "d-flex justify-content-between align-items-start px-2" }
const _hoisted_37 = { class: "mt-2 pt-2" }
const _hoisted_38 = { class: "d-flex flex-column attachment-wrapper gap-2" }
const _hoisted_39 = { class: "d-flex gap-1" }
const _hoisted_40 = { class: "filename" }
const _hoisted_41 = ["onClick"]
const _hoisted_42 = { class: "col-xxl-3 col-xl-3 box-col-3 p-0" }
const _hoisted_43 = { class: "email-right-aside p-2" }
const _hoisted_44 = { class: "p-2 search-wrapper" }
const _hoisted_45 = { class: "d-flex align-items-center gap-2" }
const _hoisted_46 = { class: "card email-body email-read show gap-2 article-wrapper overflow-wrapper" }
const _hoisted_47 = { class: "d-flex flex-column" }
const _hoisted_48 = { class: "d-flex gap-2 justify-between-content article-headline" }
const _hoisted_49 = { class: "assigned_name flex-1" }
const _hoisted_50 = ["onClick"]
const _hoisted_51 = { class: "article-content d-flex flex-column gap-1" }
const _hoisted_52 = ["innerHTML"]
const _hoisted_53 = { class: "card email-body email-read show gap-2 article-wrapper overflow-url-wrapper" }
const _hoisted_54 = { class: "d-flex flex-column" }
const _hoisted_55 = { class: "d-flex gap-2 justify-between-content article-headline" }
const _hoisted_56 = { class: "assigned_name flex-1" }
const _hoisted_57 = ["onClick"]
const _hoisted_58 = { class: "article-content d-flex flex-column gap-1" }
const _hoisted_59 = ["innerHTML"]

import VueMultiselect from "vue-multiselect";
import debounce from "lodash.debounce";
import {
  defineAsyncComponent,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  useTemplateRef,
  watch,
} from "vue";
import { useCommonStore } from "@/store/common";
import { storeToRefs } from "pinia";
import {
  GenerateEmailID,
  GetEmailByTemplate,
  GetSearchByKeyword,
  GetSearchSystemByKeyword,
  ReturnDesignJsonByAddTemplate,
  SaveAutoJson,
  SaveDraftEmailValue,
  SendEmailUsingNylas,
} from "@/services/ApiCore";
import Vue3TagsInput from "vue3-tags-input";
import TopolPlugin from "@topol.io/editor";
import { onBeforeRouteLeave } from "vue-router";

import { useRoute, useRouter } from "vue-router";
import { GetArticles } from "@/services/ApiArticles";

export default /*@__PURE__*/_defineComponent({
  __name: 'NewEmails',
  setup(__props) {

let searched_articles = ref<any>([]);
let searched_urls = ref<any>([]);
let keyword_for_article = ref<string>("");
let topolPluginInit = ref<boolean>(false);
let commonStore = useCommonStore();
const hasUnsavedChanges = ref<boolean>(false);
const { template: template } = storeToRefs(commonStore);
watch(
  () => template,
  () => {
    editor_loaded.value = false;
    setTimeout(() => {
      editor_loaded.value = true;
      nextTick(() => {
        if (commonStore.template) {
          initEditor();
        }
      });
    }, 200);
  },
  { deep: true }
);
let customOptions = ref<any>();
const uploadRef = useTemplateRef("uploadRef");
let editor_loaded = ref<boolean>(false);
let send_from_email = ref<any>("martin@easyreg.ca");
let to_subject = ref<string>("");
let send_attachments = ref<any>([]);
let to_searched_result = ref<any>([]);
let cc_searched_result = ref<any>([]);
let bcc_searched_result = ref<any>([]);
let send_to_emails = ref<any>([]);
let send_cc_emails = ref<any>([]);
let send_bcc_emails = ref<any>([]);
let currentJson = ref<string>("");
let currentHtml = ref<string>("");
let formSubmitted = ref<boolean>(false);
let sendingEmail = ref<boolean>(false);
let isLoading = ref<boolean>(false);
let isCCLoading = ref<boolean>(false);
let isBCCLoading = ref<boolean>(false);
let emails_id = ref<string>("");
let vvalue = ref<any>([]);
let autosave_interval = -1;
let router = useRouter();
function loadEditor() {
  if (topolPluginInit.value) {
    TopolPlugin.destroy();
  }
  const TOPOL_OPTIONS = {
    id: "#editor-container",
    // enableAutosaves: true,
    // api: {
    //   // Api endpoints needed for autosaves
    //   AUTOSAVE: `${process.env.apiUrl}/autosave/${emails_id.value}`,
    // },
    // autosaveInterval: 5,
    disableAlerts: true,
    authorize: {
      apiKey: process.env.TOPOL_API_KEY,
      userId: 1,
    },
    apiEndpoint: "---",
    tinyConfig: {
      selector: "textarea", // Your selector here
      paste_data_images: true, // Ensure that pasted images are handled
    },
    light: true,
    defaultTemplateSettings: {
      p: {
        "font-family": "Arial, sans-serif",
        "font-size": "14px",
        "font-weight": "400",
        "font-style": "normal",
        "background-color": "#000000",
        "line-height": 1.3,
        color: "#ffffff",
      },
      h1: {
        "font-family": "Arial, sans-serif",
        "font-size": "24px",
        "font-weight": "700",
        "font-style": "normal",
        "background-color": "#000000",
        marginTop: "30px;",
        marginTop: "20px;",
        color: "#ffffff",
      },
      h2: {
        "font-family": "Arial, sans-serif",
        "font-size": "20px",
        "font-weight": "700",
        "font-style": "normal",
        marginTop: "20px;",
        marginTop: "10px;",
        "background-color": "#000000",
        color: "#ffffff",
      },
      h3: {
        "font-family": "Arial, sans-serif",
        "font-size": "16px",
        "font-weight": "700",
        "font-style": "normal",
        marginTop: "10px;",
        marginTop: "5px;",
        "background-color": "#000000",
        color: "#ffffff",
      },
    },
    premadeBlocks: [
      {
        name: "Online Attendee Site",
        blocks: [
          {
            img:
              "https://2023-easyreg-prod.s3.ca-central-1.amazonaws.com/attachments/online_attendee_site_premade_block.png", // Image url, for best experience use width > 330 px
            name: "Online Attendee Site Block", // Or name if not image available
            // MJML JSON
            definition: [
              {
                tagName: "mj-section",
                attributes: {
                  "full-width": "600px",
                  padding: "10px 0px 10px 0px",
                  "mj-class": "section",
                },
                type: null,
                children: [
                  {
                    tagName: "mj-column",
                    attributes: {
                      width: "100%",
                      "vertical-align": "top",
                    },
                    children: [
                      {
                        tagName: "mj-text",
                        content:
                          '<p>You can access the Online Attendee Site using the following link:<br>###attendee.accesslink###</p>\n          <p style="font-weight: bold; margin-bottom: 10px; margin-top: 10px;">OR</p>\n          <ul>\n              <li>Go to https://online.ourconference.events</li>\n              <li>Enter ###attendee.email### under Email Address</li>\n              <li>Enter ###attendee.code### under Code</li>\n              <li>Click Login</li>\n          </ul>',
                        attributes: {
                          padding: "10px 10px",
                          align: "left",
                          "line-height": 1.3,
                          containerWidth: 600,
                        },
                        uid: "H1lqIiX4lm",
                      },
                    ],
                    uid: "SJ3I0XVx7",
                  },
                ],
                layout: 1,
                backgroundColor: "",
                backgroundImage: "",
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
                uid: "rkqIjQNe7",
              },
            ],
          },
        ],
      },
    ],
    customBlocks: [],
    showUnsavedDialogBeforeExit: false,
    htmlMinified: true,
    callbacks: {
      onInit(json: any, html: any) {
        topolPluginInit.value = true;
        autosave_interval = setInterval(() => {
          TopolPlugin && TopolPlugin.save();
        }, 10000);
      },
      onBlockRemove(blockId: number) {
        console.log("onBlock Remove blockId", blockId);
      },
      onBlockEdit(blockId: number) {
        console.log("onBlock Edit blockId", blockId);
      },
      async onSave(json: any, html: any) {
        currentJson.value = json;
        currentHtml.value = html;
        if (sendingEmail.value) {
          sendingEmail.value = false;
          formSubmitted.value = true;
          try {
            if (send_from_email.value == "") {
              sendingEmail.value = false;
              return;
            }
            if (to_subject.value == "") {
              sendingEmail.value = false;
              return;
            }
            const { result } = await SendEmailUsingNylas(
              emails_id.value,
              send_from_email.value,
              JSON.stringify(send_to_emails.value),
              JSON.stringify(send_cc_emails.value),
              JSON.stringify(send_bcc_emails.value),
              to_subject.value,
              currentHtml.value,
              send_attachments.value,
              [],
              "N",
              "N",
              "N"
            );
            commonStore.toggleSent(result);
            sendingEmail.value = false;
            autosave_interval = setInterval(() => {
              TopolPlugin && TopolPlugin.save();
            }, 10000);
            if (result == "success") {
              router.push("/app/emails/sent");
            } else {
            }
          } catch (e) {
            sendingEmail.value = false;
            autosave_interval = setInterval(() => {
              TopolPlugin && TopolPlugin.save();
            }, 10000);
            console.log("handleSend error", e);
          }
        } else {
          await SaveAutoJson(emails_id.value, JSON.stringify(json));
        }
      },
      onLoaded(json: any, html: any) {
        TopolPlugin.save();
      },
    },
  };
  TopolPlugin.init(TOPOL_OPTIONS);
}
function loadDesign(json: any) {
  if (json) {
    TopolPlugin.load(json);
  }
}
function initEditor() {
  (async () => {
    try {
      loadEditor();
      let template = "BLANK";
      if (commonStore.template) {
        template = commonStore.template;
      }
      const { json, html, subject } = await GetEmailByTemplate(template);
      if (subject != "" && subject != null) {
        to_subject.value = subject;
      }
      currentJson.value = json;
      currentHtml.value = html;
      loadDesign(json);
    } catch (e) {
      console.log("onMounted error", e);
    }
  })();
}
function handleAttachFiles() {
  if (uploadRef.value) {
    uploadRef.value.click();
  }
}
function handleSend() {
  (async () => {
    if (autosave_interval) {
      clearInterval(autosave_interval);
    }
    sendingEmail.value = true;
    TopolPlugin.save();
  })();
}
function generateId(len: any) {
  var arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr).join("");
}
async function loadFile(files: any) {
  const filePromises = Array.from(files).map((file: any) => {
    // Return a promise per file
    return new Promise((resolve, reject) => {
      let response = {};
      const reader = new FileReader();
      reader.onload = async () => {
        try {
          response = {
            handleId: generateId(20),
            pathname: file.name,
            name: file.name,
            size: file.size,
            type: file.type,
            image: null,
            file: file,
            uploaded: "B",
          };
          // Resolve the promise with the response value
          resolve(response);
        } catch (err) {
          reject(err);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  });
  const fileInfos = await Promise.all(filePromises);
  console.log("fileInfos", fileInfos);
  return fileInfos;
}
async function uploadFile(e: any) {
  if (e.target.files.length === 0) return;
  console.log("e.target.files", e.target.files);
  const result = await loadFile(e.target.files);
  console.log("result", result);
  send_attachments.value = [...send_attachments.value, ...result];
}
function handleRemoveAttachment(file: any) {
  send_attachments.value = send_attachments.value.filter(
    (x: any) => x.handleId != file.handleId
  );
}
async function searchArticles(e: any) {
  if (e.which == 13) {
    if (keyword_for_article.value != "") {
      const { rows, urls } = await GetArticles("keyword", keyword_for_article.value, {});
      searched_articles.value = rows;
      searched_urls.value = urls;
    }
    e.preventDefault();
  }
}
function handleInsert(article_id: any) {
  (async () => {
    try {
      const { json } = await ReturnDesignJsonByAddTemplate(
        JSON.stringify(currentJson.value),
        article_id,
        "article"
      );
      loadDesign(json);
    } catch (e) {
      console.log("onMounted error", e);
    }
  })();
}

function handleInsertURL(url_id: any) {
  (async () => {
    try {
      const { json } = await ReturnDesignJsonByAddTemplate(
        JSON.stringify(currentJson.value),
        url_id,
        "url"
      );
      loadDesign(json);
    } catch (e) {
      console.log("onMounted error", e);
    }
  })();
}

const searchBCC = debounce(async (keyword: any) => {
  if (keyword.length < 5) {
    return;
  }
  isBCCLoading.value = false;
  bcc_searched_result.value = await GetSearchByKeyword(keyword);
  isBCCLoading.value = false;
}, 500);
const searchCC = debounce(async (keyword: any) => {
  if (keyword.length < 5) {
    return;
  }
  isCCLoading.value = true;
  cc_searched_result.value = await GetSearchByKeyword(keyword);
  isCCLoading.value = false;
}, 500);
const search = debounce(async (keyword: any) => {
  if (keyword.length < 5) {
    return;
  }
  isLoading.value = true;
  to_searched_result.value = await GetSearchByKeyword(keyword);
  isLoading.value = false;
}, 500);
const addNewToEmail = debounce(async (email: any, type: string) => {
  console.log("string", type);
  const new_tag = {
    firstname: email,
    lastname: "",
    email: email,
  };
  if (type == "to") {
    send_to_emails.value.push(new_tag);
    await SaveDraftEmailValue(
      emails_id.value,
      JSON.stringify(send_to_emails.value),
      "to"
    );
  } else if (type == "cc") {
    send_cc_emails.value.push(new_tag);
    await SaveDraftEmailValue(
      emails_id.value,
      JSON.stringify(send_cc_emails.value),
      "cc"
    );
  } else if (type == "bcc") {
    send_bcc_emails.value.push(new_tag);
    await SaveDraftEmailValue(
      emails_id.value,
      JSON.stringify(send_bcc_emails.value),
      "bcc"
    );
  }
}, 500);
const pickSendFromEmail = debounce(async (email: string) => {
  send_from_email.value = email;
  await SaveDraftEmailValue(emails_id.value, email, "from");
}, 500);
const debouncedInput = debounce(async (event: Event) => {
  const target = event.target as HTMLInputElement;
  to_subject.value = target.value;
  await SaveDraftEmailValue(emails_id.value, to_subject.value, "subject");
}, 500);
onUnmounted(() => {
  if (topolPluginInit.value) {
    TopolPlugin.destroy();
  }
});
const handleChangeTo = debounce(async () => {
  if (send_to_emails.value.length > 0) {
    let send_to_emails_arr = send_to_emails.value.map((x) => {
      return {
        firstname: x.firstname,
        lastname: x.lastname,
        email: x.email,
      };
    });
    await SaveDraftEmailValue(emails_id.value, JSON.stringify(send_to_emails_arr), "to");
  }
}, 500);
const handleChangeCC = debounce(async () => {
  if (send_cc_emails.value.length > 0) {
    let send_cc_emails_arr = send_cc_emails.value.map((x) => {
      return {
        firstname: x.firstname,
        lastname: x.lastname,
        email: x.email,
      };
    });
    await SaveDraftEmailValue(emails_id.value, JSON.stringify(send_cc_emails_arr), "cc");
  }
}, 500);
const handleChangeBCC = debounce(async () => {
  if (send_bcc_emails.value.length > 0) {
    let send_bcc_emails_arr = send_bcc_emails.value.map((x) => {
      return {
        firstname: x.firstname,
        lastname: x.lastname,
        email: x.email,
      };
    });
    await SaveDraftEmailValue(
      emails_id.value,
      JSON.stringify(send_bcc_emails_arr),
      "bcc"
    );
  }
}, 500);
// onBeforeRouteLeave((to, from, next) => {
//   const answer = window.confirm("You have unsaved changes. Do you really want to leave?");
//   if (!answer) {
//     return next(false); // Block navigation
//   }
//   next(); // Allow navigation
// });
onMounted(async () => {
  emails_id.value = await GenerateEmailID();
  pickSendFromEmail("martin@easyreg.ca");
  sendingEmail.value = false;
  send_to_emails.value = [];
  send_cc_emails.value = [];
  send_bcc_emails.value = [];
  formSubmitted.value = false;
  keyword_for_article.value = "";
  searched_articles.value = [];
  send_from_email.value = "martin@easyreg.ca";
  editor_loaded.value = false;
  setTimeout(() => {
    editor_loaded.value = true;
    nextTick(() => {
      initEditor();
    });
  }, 200);
});

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "New Email",
      mains: "New Email",
      title: "New Email"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _cache[12] || (_cache[12] = _createElementVNode("label", null, " From ", -1)),
                        _createElementVNode("button", {
                          type: "button",
                          class: _normalizeClass(["btn btn-sm", 
                          _unref(send_from_email) == 'martin@easyreg.ca'
                            ? 'btn-primary text-light'
                            : 'btn-light text-dark'
                        ]),
                          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_unref(pickSendFromEmail)('martin@easyreg.ca')), ["stop"]))
                        }, " martin@easyreg.ca ", 2),
                        _createElementVNode("button", {
                          type: "button",
                          class: _normalizeClass(["btn btn-sm", 
                          _unref(send_from_email) == 'help@easyreg.ca'
                            ? 'btn-primary text-light'
                            : 'btn-light text-dark'
                        ]),
                          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_unref(pickSendFromEmail)('help@easyreg.ca')), ["stop"]))
                        }, " help@easyreg.ca ", 2)
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, [
                          _cache[14] || (_cache[14] = _createElementVNode("label", null, " To ", -1)),
                          _createVNode(_unref(VueMultiselect), {
                            modelValue: _unref(send_to_emails),
                            "onUpdate:modelValue": [
                              _cache[2] || (_cache[2] = ($event: any) => (_isRef(send_to_emails) ? (send_to_emails).value = $event : send_to_emails = $event)),
                              _unref(handleChangeTo)
                            ],
                            options: _unref(to_searched_result),
                            multiple: true,
                            taggable: true,
                            loading: _unref(isLoading),
                            searchable: true,
                            onTag: _cache[3] || (_cache[3] = ($event: any) => (_unref(addNewToEmail)($event, 'to'))),
                            onSearchChange: _unref(search),
                            placeholder: "Type to search by email, firstname, lastname",
                            label: "email",
                            "track-by": "code"
                          }, {
                            option: _withCtx((props) => [
                              (props.option)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                    (props.option.type == 'Attendee')
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(props.option.type) + " - " + _toDisplayString(props.option.firstname) + " " + _toDisplayString(props.option.lastname) + " " + _toDisplayString(`<${props.option.email}>`) + " " + _toDisplayString(props.option.event_name) + " " + _toDisplayString(props.option.event_date), 1))
                                      : _createCommentVNode("", true),
                                    (props.option.type == 'User')
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_17, " Client - " + _toDisplayString(props.option.client_abbreviation) + " - " + _toDisplayString(props.option.client_name) + " - " + _toDisplayString(props.option.firstname) + " " + _toDisplayString(props.option.lastname) + " " + _toDisplayString(`<${props.option.email}>`), 1))
                                      : _createCommentVNode("", true)
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            noResult: _withCtx(() => _cache[13] || (_cache[13] = [
                              _createTextVNode(" Oops! No elements found. Consider changing the search query. ")
                            ])),
                            _: 1
                          }, 8, ["modelValue", "options", "loading", "onSearchChange", "onUpdate:modelValue"])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("div", _hoisted_21, [
                          _cache[16] || (_cache[16] = _createElementVNode("label", null, " CC ", -1)),
                          _createVNode(_unref(VueMultiselect), {
                            modelValue: _unref(send_cc_emails),
                            "onUpdate:modelValue": [
                              _cache[4] || (_cache[4] = ($event: any) => (_isRef(send_cc_emails) ? (send_cc_emails).value = $event : send_cc_emails = $event)),
                              _unref(handleChangeCC)
                            ],
                            options: _unref(cc_searched_result),
                            loading: _unref(isCCLoading),
                            multiple: true,
                            taggable: true,
                            searchable: true,
                            onTag: _cache[5] || (_cache[5] = ($event: any) => (_unref(addNewToEmail)($event, 'cc'))),
                            onSearchChange: _unref(searchCC),
                            placeholder: "Type to search by email, firstname, lastname",
                            label: "email",
                            "track-by": "code"
                          }, {
                            option: _withCtx((props) => [
                              (props.option)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                                    (props.option.type == 'Attendee')
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(props.option.type) + " - " + _toDisplayString(props.option.firstname) + " " + _toDisplayString(props.option.lastname) + " " + _toDisplayString(`<${props.option.email}>`) + " " + _toDisplayString(props.option.event_name) + " " + _toDisplayString(props.option.event_date), 1))
                                      : _createCommentVNode("", true),
                                    (props.option.type == 'User')
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_24, " Client - " + _toDisplayString(props.option.client_abbreviation) + " - " + _toDisplayString(props.option.client_name) + " - " + _toDisplayString(props.option.firstname) + " " + _toDisplayString(props.option.lastname) + " " + _toDisplayString(`<${props.option.email}>`), 1))
                                      : _createCommentVNode("", true)
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            noResult: _withCtx(() => _cache[15] || (_cache[15] = [
                              _createTextVNode(" Oops! No elements found. Consider changing the search query. ")
                            ])),
                            _: 1
                          }, 8, ["modelValue", "options", "loading", "onSearchChange", "onUpdate:modelValue"])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("div", _hoisted_27, [
                        _createElementVNode("div", _hoisted_28, [
                          _cache[18] || (_cache[18] = _createElementVNode("label", null, " BCC ", -1)),
                          _createVNode(_unref(VueMultiselect), {
                            modelValue: _unref(send_bcc_emails),
                            "onUpdate:modelValue": [
                              _cache[6] || (_cache[6] = ($event: any) => (_isRef(send_bcc_emails) ? (send_bcc_emails).value = $event : send_bcc_emails = $event)),
                              _unref(handleChangeBCC)
                            ],
                            options: _unref(bcc_searched_result),
                            loading: _unref(isBCCLoading),
                            multiple: true,
                            taggable: true,
                            searchable: true,
                            onTag: _cache[7] || (_cache[7] = ($event: any) => (_unref(addNewToEmail)($event, 'bcc'))),
                            onSearchChange: _unref(searchBCC),
                            placeholder: "Type to search by email, firstname, lastname",
                            label: "email",
                            "track-by": "code"
                          }, {
                            option: _withCtx((props) => [
                              (props.option)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                                    (props.option.type == 'Attendee')
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_30, _toDisplayString(props.option.type) + " - " + _toDisplayString(props.option.firstname) + " " + _toDisplayString(props.option.lastname) + " " + _toDisplayString(`<${props.option.email}>`) + " " + _toDisplayString(props.option.event_name) + " " + _toDisplayString(props.option.event_date), 1))
                                      : _createCommentVNode("", true),
                                    (props.option.type == 'User')
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_31, " Client - " + _toDisplayString(props.option.client_abbreviation) + " - " + _toDisplayString(props.option.client_name) + " - " + _toDisplayString(props.option.firstname) + " " + _toDisplayString(props.option.lastname) + " " + _toDisplayString(`<${props.option.email}>`), 1))
                                      : _createCommentVNode("", true)
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            noResult: _withCtx(() => _cache[17] || (_cache[17] = [
                              _createTextVNode(" Oops! No elements found. Consider changing the search query. ")
                            ])),
                            _: 1
                          }, 8, ["modelValue", "options", "loading", "onSearchChange", "onUpdate:modelValue"])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_32, [
                    _createElementVNode("div", _hoisted_33, [
                      _createElementVNode("div", _hoisted_34, [
                        _cache[19] || (_cache[19] = _createElementVNode("label", { class: "col-form-label" }, "Subject", -1)),
                        _withDirectives(_createElementVNode("input", {
                          class: _normalizeClass(["form-control", 
                          _unref(formSubmitted)
                            ? _unref(to_subject) == ''
                              ? 'is-invalid'
                              : 'is-valid'
                            : ''
                        ]),
                          id: "composeFrom",
                          type: "text",
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_isRef(to_subject) ? (to_subject).value = $event : to_subject = $event)),
                          onInput: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_unref(debouncedInput) && _unref(debouncedInput)(...args)))
                        }, null, 34), [
                          [_vModelText, _unref(to_subject)]
                        ]),
                        (_unref(formSubmitted) && _unref(to_subject) == '')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_35, " Subject is empty! "))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]),
                  _cache[20] || (_cache[20] = _createElementVNode("div", { class: "editor-wrapper" }, [
                    _createElementVNode("div", {
                      id: "editor-container",
                      class: "w-full"
                    })
                  ], -1))
                ]),
                _createElementVNode("div", _hoisted_36, [
                  _createElementVNode("div", _hoisted_37, [
                    _createElementVNode("div", _hoisted_38, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(send_attachments), (send_attachment, send_attachment_index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: `attachment-index-${send_attachment_index}`,
                          class: "d-flex justify-content-between gap-2 attachment-item"
                        }, [
                          _createElementVNode("div", _hoisted_39, [
                            _createElementVNode("span", _hoisted_40, _toDisplayString(send_attachment.name), 1),
                            _createElementVNode("span", null, "(" + _toDisplayString(_ctx.$filters.filesize(send_attachment.size)) + ")", 1)
                          ]),
                          _createElementVNode("button", {
                            class: "btn-close btn-sm",
                            onClick: _withModifiers(($event: any) => (handleRemoveAttachment(send_attachment)), ["stop"])
                          }, null, 8, _hoisted_41)
                        ]))
                      }), 128))
                    ])
                  ]),
                  _createElementVNode("div", { class: "send-btn d-flex gap-2 justify-content-end mb-4" }, [
                    _createElementVNode("button", {
                      class: "btn btn-secondary d-flex gap-2",
                      type: "button",
                      onClick: handleAttachFiles
                    }, _cache[21] || (_cache[21] = [
                      _createTextVNode(" Attach files"),
                      _createElementVNode("i", { class: "fa-solid fa-paperclip" }, null, -1)
                    ])),
                    _createElementVNode("button", {
                      class: "btn btn-primary d-flex gap-2",
                      type: "button",
                      onClick: handleSend
                    }, _cache[22] || (_cache[22] = [
                      _createTextVNode(" Send"),
                      _createElementVNode("i", { class: "fa-solid fa-paper-plane" }, null, -1)
                    ]))
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_42, [
            _createElementVNode("div", _hoisted_43, [
              _cache[27] || (_cache[27] = _createElementVNode("div", { class: "p-2 pb-0 article-wrapper" }, [
                _createElementVNode("span", { class: "text" }, "Search Articles")
              ], -1)),
              _createElementVNode("div", _hoisted_44, [
                _createElementVNode("div", _hoisted_45, [
                  _cache[24] || (_cache[24] = _createElementVNode("i", { class: "search-bg iconly-Search icli" }, null, -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: "demo-input py-0 Typeahead-input form-control-plaintext w-100",
                    type: "text",
                    placeholder: "Search for keywords or title",
                    title: "",
                    onKeyup: searchArticles,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_isRef(keyword_for_article) ? (keyword_for_article).value = $event : keyword_for_article = $event))
                  }, null, 544), [
                    [_vModelText, _unref(keyword_for_article)]
                  ]),
                  (_unref(keyword_for_article) != '')
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        onClick: _cache[11] || (_cache[11] = _withModifiers(($event: any) => (_isRef(keyword_for_article) //@ts-ignore
 ? keyword_for_article.value = '' : keyword_for_article = ''), ["stop"])),
                        class: "cursor-pointer close-btn d-flex align-items-center"
                      }, _cache[23] || (_cache[23] = [
                        _createElementVNode("svg", {
                          xmlns: "http://www.w3.org/2000/svg",
                          className: "h-6 w-6",
                          fill: "none",
                          viewBox: "0 0 24 24",
                          stroke: "currentColor"
                        }, [
                          _createElementVNode("path", {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "3",
                            d: "M6 18L18 6M6 6l12 12"
                          })
                        ], -1)
                      ])))
                    : _createCommentVNode("", true)
                ])
              ]),
              (_unref(searched_articles).length > 0)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _cache[25] || (_cache[25] = _createElementVNode("div", { class: "mt-1 p-2" }, [
                      _createElementVNode("span", { class: "badge badge-success" }, [
                        _createElementVNode("h4", null, "Articles")
                      ])
                    ], -1)),
                    _createElementVNode("div", _hoisted_46, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(searched_articles), (searched_article, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: `searched-article-${index}`,
                          class: "flex flex-column align-items-start p-2 article-searched-wrapper"
                        }, [
                          _createElementVNode("div", _hoisted_47, [
                            _createElementVNode("div", _hoisted_48, [
                              _createElementVNode("span", _hoisted_49, _toDisplayString(searched_article.article_headline), 1),
                              _createElementVNode("button", {
                                type: "button",
                                onClick: _withModifiers(($event: any) => (handleInsert(searched_article.article_id)), ["stop"]),
                                class: "btn btn-md btn-light text-dark btn-receipt mb-1"
                              }, " Insert ", 8, _hoisted_50)
                            ])
                          ]),
                          _createElementVNode("div", null, [
                            _createElementVNode("span", _hoisted_51, [
                              _createElementVNode("div", {
                                innerHTML: searched_article.article_text
                              }, null, 8, _hoisted_52)
                            ])
                          ])
                        ]))
                      }), 128))
                    ])
                  ], 64))
                : _createCommentVNode("", true),
              (_unref(searched_urls).length > 0)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _cache[26] || (_cache[26] = _createElementVNode("div", { class: "mt-1 p-2" }, [
                      _createElementVNode("span", { class: "badge badge-success" }, [
                        _createElementVNode("h4", null, "Tiny URLs")
                      ])
                    ], -1)),
                    _createElementVNode("div", _hoisted_53, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(searched_urls), (searched_url, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: `searched-url-${index}`,
                          class: "flex flex-column align-items-start p-2 article-searched-wrapper"
                        }, [
                          _createElementVNode("div", _hoisted_54, [
                            _createElementVNode("div", _hoisted_55, [
                              _createElementVNode("span", _hoisted_56, _toDisplayString(searched_url.url_keywords), 1),
                              _createElementVNode("button", {
                                type: "button",
                                onClick: _withModifiers(($event: any) => (handleInsertURL(searched_url.url_id)), ["stop"]),
                                class: "btn btn-md btn-light text-dark btn-receipt mb-1"
                              }, " Insert ", 8, _hoisted_57)
                            ])
                          ]),
                          _createElementVNode("div", null, [
                            _createElementVNode("span", _hoisted_58, [
                              _createElementVNode("div", {
                                innerHTML: searched_url.url_short
                              }, null, 8, _hoisted_59)
                            ])
                          ])
                        ]))
                      }), 128))
                    ])
                  ], 64))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      _createElementVNode("input", {
        type: "file",
        accept: "*",
        hidden: "",
        ref_key: "uploadRef",
        ref: uploadRef,
        onChange: uploadFile
      }, null, 544)
    ])
  ], 64))
}
}

})