import ApiInstance from "./ApiBase";

export const GetEmailList = async (page: number, category: string, star: string = 'N'): Promise<any> => {
    const res = await ApiInstance.get(`/get-email-list/${page}/${category}/${star}`);
    return res?.data;
};

export const DeleteDraft = async (emails_id: string): Promise<any> => {
    const res = await ApiInstance.delete(`/delete-draft/${emails_id}`);
    return res?.data;
};


export const GetEmailCategoryBadge = async (): Promise<any> => {
    const res = await ApiInstance.get(`/get-email-category-badge`);
    return res?.data;
};

export const GetEmailSync = async (tab: string, emails_id: string): Promise<any> => {
    const res = await ApiInstance.get(`/get-email-sync/${tab}/${emails_id}`);
    return res?.data;
};
export const GetSearchSystemByKeyword = async (keyword: string): Promise<any> => {
    // const res = await ApiInstance.get(`http://localhost:8888/api/checkEmailCRMByKeyword/${keyword}`);
    const res = await ApiInstance.get(`https://api.easyreg2023.ca/api/checkEmailCRMByKeyword/${keyword}`);
    return res?.data;
};
export const GetSearchByKeyword = async (
    keyword: string,
): Promise<any> => {
    // const res = await ApiInstance.get(`http://localhost:8888/api/checkFullEmailCRM/${keyword}`);
    const res = await ApiInstance.get(`https://api.easyreg2023.ca/api/checkFullEmailCRM/${keyword}`);
    return res?.data;
};
export const GetAssignsByEmail = async (emails_id: string): Promise<any> => {
    const res = await ApiInstance.get(`/get-assigns-by-email/${emails_id}`);
    return res?.data;
};

export const GetInitDesign = async (emails_id: string, forward_email: string): Promise<any> => {
    const res = await ApiInstance.get(`/get-init-design/${emails_id}/${forward_email}`);
    return res?.data;
};
export const ReturnDesignJsonByAddTemplate = async (
    json: string,
    article_id: any,
    type: string
): Promise<any> => {
    const data = new FormData();
    data.append("json", json);
    data.append("article_id", article_id);
    data.append("type", type);
    const res = await ApiInstance.post(`return-design-json-by-add-template`, data);
    return res?.data;
};


export const SendEmailUsingNylas = async (
    emails_id: string,
    from_email: string,
    to_emails: string,
    cc_emails: string,
    bcc_emails: string,
    subject: string,
    body: string,
    attachments: any,
    attachments_attached: any,
    forward_email: string,
    email_closed: string,
    email_receipt: string
): Promise<any> => {
    const data = new FormData();
    data.append("emails_id", emails_id);
    data.append("from_email", from_email);
    data.append("to_emails", to_emails);
    data.append("cc_emails", cc_emails);
    data.append("bcc_emails", bcc_emails);
    data.append("subject", subject);
    data.append("body", body);
    data.append("attached", attachments_attached.length);
    for (var idx = 0; idx < attachments_attached.length; idx++) {
        data.append(`attachments_attached_file_${idx}`, attachments_attached[idx].file);
        data.append(`attachments_attached_name_${idx}`, attachments_attached[idx].name);
        data.append(`attachments_attached_type_${idx}`, attachments_attached[idx].type);
    }
    for (var idx = 0; idx < attachments.length; idx++) {
        data.append(`attachments-${idx}`, attachments[idx].file);
    }
    data.append("email_closed", email_closed);
    data.append("email_receipt", email_receipt);
    const res = await ApiInstance.post(`send-email-using-nylas`, data);
    return res?.data;
};

export const SetEmailClose = async (
    emails_id: string,
): Promise<any> => {
    const data = new FormData();
    data.append("emails_id", emails_id);
    const res = await ApiInstance.post(`set-email-close`, data);
    return res?.data;
};
export const SaveAutoJson = async (
    emails_id: string,
    emails_json: string,
): Promise<any> => {
    const data = new FormData();
    data.append("emails_id", emails_id);
    data.append("emails_json", emails_json);
    const res = await ApiInstance.post(`save-auto-json`, data);
    return res?.data;
};


export const SetEmailStar = async (
    emails_id: string,
): Promise<any> => {
    const data = new FormData();
    data.append("emails_id", emails_id);
    const res = await ApiInstance.post(`set-email-star`, data);
    return res?.data;
};

export const SetEmailCategory = async (
    emails_id: string,
    category: string,
): Promise<any> => {
    const data = new FormData();
    data.append("emails_id", emails_id);
    data.append("category", category);
    const res = await ApiInstance.post(`set-email-category`, data);
    return res?.data;
};

export const SaveRule = async (
    rule_type: string,
    rule_text: string,
    rule_category: string,
): Promise<any> => {
    const data = new FormData();
    data.append("rule_type", rule_type);
    data.append("rule_text", rule_text);
    data.append("rule_category", rule_category);
    const res = await ApiInstance.post(`save-rule`, data);
    return res?.data;
};



export const UploadDisputeRecordFile = async (
    file: any
): Promise<any> => {
    const data = new FormData();
    data.append("file", file);
    const res = await ApiInstance.post(`https://api.easyreg2023.ca/api/uploadDisputeRecordFile`, data);
    return res?.data;
};
export const DownloadDisputeFile = async (
    name: string,
    path: string
): Promise<any> => {
    const data = new FormData();
    data.append("name", name);
    data.append("path", path);
    const res = await ApiInstance.post(`https://api.easyreg2023.ca/api/downloadDisputeFile`, data);
    return res?.data;
};

export const TerminateInstance = async (
    region: string,
    instanceId: string,
): Promise<any> => {
    const data = new FormData();
    data.append("region", region);
    data.append("instanceId", instanceId);
    const res = await ApiInstance.post(`https://api.easyreg2023.ca/api/terminate-instance`, data);
    return res?.data;
};

export const RemoveIAMUser = async (
    UserName: string,
): Promise<any> => {
    const data = new FormData();
    data.append("UserName", UserName);
    const res = await ApiInstance.post(`https://api.easyreg2023.ca/api/remove-iam-user`, data);
    // const res = await ApiInstance.post(`http://localhost:8888/api/remove-iam-user`, data);
    return res?.data;
};

export const GetCostAndUsage = async (
    start: string,
    end: string
): Promise<any> => {
    const data = new FormData();
    data.append("start", start);
    data.append("end", end);
    const res = await ApiInstance.post(`https://api.easyreg2023.ca/api/getCostAndUsage`, data);
    return res?.data;
};



export const GetEC2Dashboard = async (): Promise<any> => {
    const res = await ApiInstance.get(`https://api.easyreg2023.ca/api/getEC2Dashboard`);
    return res?.data;
};
export const GetIAMUsers = async (): Promise<any> => {
    const res = await ApiInstance.get(`https://api.easyreg2023.ca/api/getIAMUsers`);
    return res?.data;
};

export const GetSystemDashboard = async (): Promise<any> => {
    const res = await ApiInstance.get(`https://api.easyreg2023.ca/api/getSystemDashboard`);
    return res?.data;
};

export const GetEmailQueue = async (): Promise<any> => {
    const res = await ApiInstance.get(`https://api.easyreg2023.ca/api/getEmailQueue`);
    return res?.data;
};

export const GetSMSQueue = async (): Promise<any> => {
    const res = await ApiInstance.get(`https://api.easyreg2023.ca/api/getSMSQueue`);
    return res?.data;
};

export const GetMailGunFailedQueue = async (page: number): Promise<any> => {
    const res = await ApiInstance.get(`https://api.easyreg2023.ca/api/getMailGunFailedQueue/${page}`);
    return res?.data;
};

export const GetEmailQueueByID = async (ID: any, type: string): Promise<any> => {
    const res = await ApiInstance.get(`https://api.easyreg2023.ca/api/getEmailQueueByID/${ID}/${type}`);
    return res?.data;
};

export const GetSMSQueueByID = async (ID: any): Promise<any> => {
    const res = await ApiInstance.get(`https://api.easyreg2023.ca/api/getSMSQueueByID/${ID}`);
    return res?.data;
};
export const ResendSMS = async (
    ID: string,
): Promise<any> => {
    const data = new FormData();
    data.append("ID", ID);
    const res = await ApiInstance.post(`https://api.easyreg2023.ca/api/resend-sms`, data);
    return res?.data;
};

export const GetToDoReference = async (
    type: string,
    reference: string
): Promise<any> => {
    const data = new FormData();
    data.append("type", type);
    data.append("reference", reference);
    const res = await ApiInstance.post(`https://api.easyreg2023.ca/api/get-email-reference`, data);
    return res?.data;
};


export const ResendEmailSendGrid = async (
    ID: string,
): Promise<any> => {
    const data = new FormData();
    data.append("ID", ID);
    const res = await ApiInstance.post(`https://api.easyreg2023.ca/api/resend-email-sendgrid`, data);
    return res?.data;
};
export const ResendEmailMailgun = async (
    ID: string,
): Promise<any> => {
    const data = new FormData();
    data.append("ID", ID);
    const res = await ApiInstance.post(`https://api.easyreg2023.ca/api/resend-email-mailgun`, data);
    return res?.data;
};
export const DeleteMailGunFailed = async (
    ID: string,
): Promise<any> => {
    const data = new FormData();
    data.append("ID", ID);
    const res = await ApiInstance.post(`https://api.easyreg2023.ca/api/delete-mailgun-failed`, data);
    return res?.data;
};
export const RemoveMailGunFailed = async (
    ID: string,
): Promise<any> => {
    const res = await ApiInstance.get(`https://api.easyreg2023.ca/api/removeMailGunFailed/${ID}`);
    return res?.data;
};
export const DeleteQueueEntry = async (
    type: string,
    ID: string,
): Promise<any> => {
    const data = new FormData();
    data.append("type", type);
    data.append("ID", ID);
    const res = await ApiInstance.post(`https://api.easyreg2023.ca/api/deleteQueueEntry`, data);
    return res?.data;
};


export const SaveQueueEntry = async (
    type: string,
    ID: string,
    failed_ID: string,
    value: string,
    name: string,
    resend_mailgun: string
): Promise<any> => {
    const data = new FormData();
    data.append("type", type);
    data.append("ID", ID);
    data.append("failed_ID", failed_ID);
    data.append("value", value);
    data.append("name", name);
    data.append("resend_mailgun", resend_mailgun);
    const res = await ApiInstance.post(`https://api.easyreg2023.ca/api/saveQueueEntry`, data);
    return res?.data;
};

export const AssignEmail = async (
    emails_id: string,
    assign_id: string,
): Promise<any> => {
    const data = new FormData();
    data.append("emails_id", emails_id);
    data.append("assign_id", assign_id);
    const res = await ApiInstance.post(`assign-email`, data);
    return res?.data;
};


export const AssignEmailBySearched = async (
    emails_id: string,
    item: string,
): Promise<any> => {
    const data = new FormData();
    data.append("emails_id", emails_id);
    data.append("item", item);
    const res = await ApiInstance.post(`assign-email-by-searched`, data);
    return res?.data;
};


export const GetEmailTemplates = async (page: number, keyword: string = 'all'): Promise<any> => {
    const res = await ApiInstance.get(`/get-email-templates/${page}/${keyword}`);
    return res?.data;
};
export const GetDesignByTemplate = async (template: string): Promise<any> => {
    const res = await ApiInstance.get(`/get-design-by-template/${template}`);
    return res?.data;
};
export const GetEmailByTemplate = async (template: string): Promise<any> => {
    const res = await ApiInstance.get(`/get-email-by-template/${template}`);
    return res?.data;
};
export const GenerateEmailID = async (): Promise<any> => {
    const res = await ApiInstance.get(`/generate-email-id`);
    return res?.data;
};
export const SaveDraftEmailValue = async (
    emails_id: string,
    emails_value: string,
    emails_audience: string
): Promise<any> => {
    const data = new FormData();
    data.append("emails_id", emails_id);
    data.append("emails_value", emails_value);
    data.append("emails_audience", emails_audience);
    const res = await ApiInstance.post(`save-draft-email-values`, data);
    return res?.data;
};


export const SaveEmailByTemplate = async (
    template_id: string,
    template_description: string,
    template_subject: string,
    template_edit_type: string
): Promise<any> => {
    const data = new FormData();
    data.append("template_id", template_id);
    data.append("template_description", template_description);
    data.append("template_subject", template_subject);
    data.append("template_edit_type", template_edit_type);
    const res = await ApiInstance.post(`save-email-by-template`, data);
    return res?.data;
};

export const DeleteEmailByTemplate = async (template: string): Promise<any> => {
    const res = await ApiInstance.delete(`/delete-email-by-template/${template}`);
    return res?.data;
};

export const SaveTemplateDesign = async (
    json: string,
    html: string,
    content: string,
    template_id: string,
): Promise<any> => {
    const res = await ApiInstance.post(`/save-template-design`, {
        json: json,
        html: html,
        content: content,
        template_id: template_id,
    });
    return res?.data;
};
