import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "email-wrap email-main-wrapper" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-xxl-12 col-xl-12 box-col-12" }
const _hoisted_5 = { class: "email-right-aside" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = { class: "table-responsive theme-scrollbar" }
const _hoisted_9 = { class: "table table-dashed" }
const _hoisted_10 = { class: "d-flex gap-2" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["onClick"]

import VueMultiselect from "vue-multiselect";
import { defineAsyncComponent, onMounted, ref } from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import { openModal } from "@kolirt/vue-modal";
import { useRouter } from "vue-router";
import { GetToDos, ManageToDos } from "@/services/ApiToDos";
import dayjs from "dayjs";
import { toast } from "vue3-toastify";
import { GetToDoReference } from "@/services/ApiCore";
// Define refs to hold rule data and current pagination state

export default /*@__PURE__*/_defineComponent({
  __name: 'IndexToDo',
  setup(__props) {

const allToDos = ref<any[]>([]); // Stores all loaded rules
const ManageEntry = defineAsyncComponent(
  () => import("@/components/theme/dashboards/default/ManageEntry.vue")
);
// Import component for rule management modal
const CreateToDo = defineAsyncComponent(
  () => import("@/components/modal/CreateToDo.vue")
);
// Router instance for navigation if needed
const router = useRouter();

function formatDate(dateString: string) {
  const date = dayjs(dateString);
  // Then specify how you want your dates to be formatted
  return date.format("dddd MMMM D, YYYY");
}

/**
 * Opens modal for creating a new rule.
 */
function handleCreate() {
  openModal(CreateToDo, { todo_id: "", todo_action: "NEW" })
    .then(() => {
      // Reload rules after editing to reflect changes
      loadToDos();
    })
    .catch(() => {
      console.log("Edit modal closed without saving.");
    });
}

function handleView(todo_id: any) {
  openModal(CreateToDo, { todo_id, todo_action: "update" })
    .then(() => {
      // Reload rules after editing to reflect changes
      loadToDos();
    })
    .catch(() => {
      console.log("Edit modal closed without saving.");
    });
}
async function handleFix(todo_id: any, todo_type: any, todo_reference: any) {
  const { type, ID, failed_ID } = await GetToDoReference(todo_type, todo_reference);
  if (ID != "") {
    openModal(ManageEntry, {
      type,
      ID,
      failed_ID,
    })
      // runs when modal is closed via confirmModal
      .then(async (data) => {
        await ManageToDos(todo_id, "", "", "", "", "close");
        loadToDos();
      })
      // runs when modal is closed via closeModal or esc
      .catch(() => {
        console.log("catch");
      });
  } else {
    toast.danger("Reference Failed", {
      position: "top-right",
      autoClose: 2000,
    });
  }
}
function handleDone(todo_id: any) {
  (async () => {
    try {
      await ManageToDos(todo_id, "", "", "", "", "close");
      loadToDos();
    } catch (error) {
      console.log("error", error);
    }
  })();
}

function handleDelete(todo_id: any) {
  (async () => {
    try {
      await ManageToDos(todo_id, "", "", "","", "delete");
      loadToDos();
    } catch (error) {
      console.log("error", error);
    }
  })();
}

function loadToDos() {
  (async () => {
    try {
      const { rows } = await GetToDos("all", 1, {});
      allToDos.value = rows;
    } catch (error) {
      console.log("error", error);
    }
  })();
}
onMounted(() => {
  loadToDos();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", { class: "container-fluid" }, [
      _createElementVNode("div", { class: "page-title" }, [
        _createElementVNode("div", { class: "row" }, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-sm-6 col-12" }, [
            _createElementVNode("h2", null, "To Dos")
          ], -1)),
          _createElementVNode("div", { class: "col-sm-6 col-12 d-flex gap-2 justify-content-end" }, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-md btn-primary text-light",
              onClick: handleCreate
            }, " Create To Do ")
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("table", _hoisted_9, [
                      _cache[1] || (_cache[1] = _createElementVNode("thead", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", { scope: "col" }),
                          _createElementVNode("th", { scope: "col" }, "Type"),
                          _createElementVNode("th", { scope: "col" }, "To Do"),
                          _createElementVNode("th", { scope: "col" }, "Due")
                        ])
                      ], -1)),
                      _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(allToDos.value, (todo, index) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: `todo-${index}`
                          }, [
                            _createElementVNode("td", null, [
                              _createElementVNode("div", _hoisted_10, [
                                _createElementVNode("button", {
                                  type: "button",
                                  class: "btn btn-sm btn-light text-dark btn-action",
                                  onClick: ($event: any) => (handleView(todo.todo_id))
                                }, " Edit ", 8, _hoisted_11),
                                _createElementVNode("button", {
                                  type: "button",
                                  class: "btn btn-sm btn-light text-dark btn-action",
                                  onClick: ($event: any) => (handleDelete(todo.todo_id))
                                }, " Delete ", 8, _hoisted_12),
                                _createElementVNode("button", {
                                  type: "button",
                                  class: "btn btn-sm btn-light text-dark btn-action",
                                  onClick: ($event: any) => (handleDone(todo.todo_id))
                                }, " Done ", 8, _hoisted_13),
                                (
                                todo.todo_type == 'emailerror' ||
                                todo.todo_type == 'mailgunfailed'
                              )
                                  ? (_openBlock(), _createElementBlock("button", {
                                      key: 0,
                                      type: "button",
                                      class: "btn btn-sm btn-light text-dark btn-action",
                                      onClick: ($event: any) => (
                                  handleFix(
                                    todo.todo_id,
                                    todo.todo_type,
                                    todo.todo_reference
                                  )
                                )
                                    }, " Fix ", 8, _hoisted_14))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            _createElementVNode("td", null, _toDisplayString(todo.todo_type), 1),
                            _createElementVNode("td", null, _toDisplayString(todo.todo_description), 1),
                            _createElementVNode("td", null, _toDisplayString(formatDate(todo.todo_DueDate)), 1)
                          ]))
                        }), 128))
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})