<template>
  <Breadcrumbs main="Email" mains="Emails" title="Emails" />
  <div class="container-fluid">
    <div class="email-wrap email-main-wrapper">
      <div class="row">
        <loading
          v-model:active="isLoading"
          :can-cancel="true"
          :is-full-page="false"
          :height="40"
          :width="40"
          :loader="`dots`"
          :color="`#2B5582`"
          :background-color="`#FFFFFF`"
        />
        <div class="col-xxl-9 col-xl-9 box-col-9">
          <div class="email-right-aside">
            <div class="card email-body email-read show">
              <div class="mail-body-wrapper email-reply-body-wrapper">
                <div class="user-mail-wrapper">
                  <div class="user-footer">
                    <div class="toolbar-box">
                      <div class="row mb-1">
                        <div class="col-sm-12 px-0 py-0">
                          <div class="card-wrapper py-0 d-flex gap-2">
                            <label> From </label>

                            <button
                              type="button"
                              class="btn btn-sm"
                              :class="
                                send_from_email == 'martin@easyreg.ca'
                                  ? 'btn-primary text-light'
                                  : 'btn-light text-dark'
                              "
                              @click.stop="pickSendFromEmail('martin@easyreg.ca')"
                            >
                              martin@easyreg.ca
                            </button>

                            <button
                              type="button"
                              class="btn btn-sm"
                              :class="
                                send_from_email == 'help@easyreg.ca'
                                  ? 'btn-primary text-light'
                                  : 'btn-light text-dark'
                              "
                              @click.stop="pickSendFromEmail('help@easyreg.ca')"
                            >
                              help@easyreg.ca
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="flex flex-col email_fields">
                        <div class="row mb-1">
                          <div class="col-sm-12 px-0 py-0">
                            <div class="card-wrapper py-0 d-flex gap-2">
                              <label> To </label>
                              <VueMultiselect
                                v-model="send_to_emails"
                                :options="to_searched_result"
                                :multiple="true"
                                :taggable="true"
                                :loading="isToLoading"
                                :searchable="true"
                                @tag="addNewToEmail($event, 'to')"
                                @search-change="search"
                                placeholder="Type to search by email, firstname, lastname"
                                label="email"
                                track-by="code"
                                @update:modelValue="handleChangeTo"
                              >
                                <template #option="props">
                                  <template v-if="props.option">
                                    <div class="option__desc">
                                      <template v-if="props.option.type == 'Attendee'">
                                        <span class="option__title"
                                          >{{ props.option.type }} -
                                          {{ props.option.firstname }}
                                          {{ props.option.lastname }}
                                          {{ `<${props.option.email}>` }}
                                          {{ props.option.event_name }}
                                          {{ props.option.event_date }}
                                        </span>
                                      </template>
                                      <template v-if="props.option.type == 'User'">
                                        <span class="option__title">
                                          Client -
                                          {{ props.option.client_abbreviation }} -
                                          {{ props.option.client_name }} -
                                          {{ props.option.firstname }}
                                          {{ props.option.lastname }}
                                          {{ `<${props.option.email}>` }}
                                        </span>
                                      </template>
                                      <template v-if="props.option.type == 'Unknown'">
                                        <span class="option__title">
                                          {{ props.option.type }} -
                                          {{ props.option.firstname }}
                                          {{ props.option.lastname }}
                                          {{ `<${props.option.email}>` }}
                                        </span>
                                      </template>
                                    </div>
                                  </template>
                                </template>
                                <template #noResult>
                                  Oops! No elements found. Consider changing the search
                                  query.
                                </template>
                              </VueMultiselect>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-1">
                          <div class="col-sm-12 px-0 py-0">
                            <div class="card-wrapper py-0 d-flex gap-2">
                              <label> CC </label>
                              <VueMultiselect
                                v-model="send_cc_emails"
                                :options="cc_searched_result"
                                :loading="isCCLoading"
                                :multiple="true"
                                :taggable="true"
                                :searchable="true"
                                @tag="addNewToEmail($event, 'cc')"
                                @search-change="searchCC"
                                placeholder="Type to search by email, firstname, lastname"
                                label="email"
                                track-by="code"
                                @update:modelValue="handleChangeCC"
                              >
                                <template #option="props">
                                  <template v-if="props.option">
                                    <div class="option__desc">
                                      <template v-if="props.option.type == 'Attendee'">
                                        <span class="option__title"
                                          >{{ props.option.type }} -
                                          {{ props.option.firstname }}
                                          {{ props.option.lastname }}
                                          {{ `<${props.option.email}>` }}
                                          {{ props.option.event_name }}
                                          {{ props.option.event_date }}
                                        </span>
                                      </template>
                                      <template v-if="props.option.type == 'User'">
                                        <span class="option__title">
                                          Client -
                                          {{ props.option.client_abbreviation }} -
                                          {{ props.option.client_name }} -
                                          {{ props.option.firstname }}
                                          {{ props.option.lastname }}
                                          {{ `<${props.option.email}>` }}
                                        </span>
                                      </template>
                                      <template v-if="props.option.type == 'Unknown'">
                                        <span class="option__title">
                                          {{ props.option.type }} -
                                          {{ props.option.firstname }}
                                          {{ props.option.lastname }}
                                          {{ `<${props.option.email}>` }}
                                        </span>
                                      </template>
                                    </div>
                                  </template>
                                </template>
                                <template #noResult>
                                  Oops! No elements found. Consider changing the search
                                  query.
                                </template>
                              </VueMultiselect>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-1">
                          <div class="col-sm-12 px-0 py-0">
                            <div class="card-wrapper py-0 d-flex gap-2">
                              <label> BCC </label>
                              <VueMultiselect
                                v-model="send_bcc_emails"
                                :options="bcc_searched_result"
                                :loading="isBCCLoading"
                                :multiple="true"
                                :taggable="true"
                                :searchable="true"
                                @tag="addNewToEmail($event, 'bcc')"
                                @search-change="searchBCC"
                                placeholder="Type to search by email, firstname, lastname"
                                label="email"
                                track-by="code"
                                @update:modelValue="handleChangeBCC"
                              >
                                <template #option="props">
                                  <template v-if="props.option">
                                    <div class="option__desc">
                                      <template v-if="props.option.type == 'Attendee'">
                                        <span class="option__title"
                                          >{{ props.option.type }} -
                                          {{ props.option.firstname }}
                                          {{ props.option.lastname }}
                                          {{ `<${props.option.email}>` }}
                                          {{ props.option.event_name }}
                                          {{ props.option.event_date }}
                                        </span>
                                      </template>
                                      <template v-if="props.option.type == 'User'">
                                        <span class="option__title">
                                          Client -
                                          {{ props.option.client_abbreviation }} -
                                          {{ props.option.client_name }} -
                                          {{ props.option.firstname }}
                                          {{ props.option.lastname }}
                                          {{ `<${props.option.email}>` }}
                                        </span>
                                      </template>
                                      <template v-if="props.option.type == 'Unknown'">
                                        <span class="option__title">
                                          {{ props.option.type }} -
                                          {{ props.option.firstname }}
                                          {{ props.option.lastname }}
                                          {{ `<${props.option.email}>` }}
                                        </span>
                                      </template>
                                    </div>
                                  </template>
                                </template>
                                <template #noResult>
                                  Oops! No elements found. Consider changing the search
                                  query.
                                </template>
                              </VueMultiselect>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row mb-1">
                        <div class="col-sm-12 px-0 py-0">
                          <div class="card-wrapper py-0 d-flex gap-2">
                            <label class="col-form-label">Subject</label>
                            <input
                              class="form-control"
                              id="composeFrom"
                              type="text"
                              v-model="to_subject"
                              @input="debouncedInput"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="editor-wrapper">
                        <div id="editor-container" class="w-full"></div>
                      </div>

                      <div class="d-flex gap-2 justify-content-end my-2">
                        <div class="common-flex switch-wrapper icon-switch">
                          <div class="d-flex">
                            <label class="col-form-label m-r-10">Close</label>
                            <div class="flex-grow-1 text-end">
                              <label class="switch">
                                <input type="checkbox" v-model="email_closed" /><span
                                  class="switch-state"
                                ></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <template
                          v-if="
                            email_easyreg.length == 1 && email_easyreg[0].status == 'A'
                          "
                        >
                          <div class="common-flex switch-wrapper icon-switch">
                            <div class="d-flex">
                              <label class="col-form-label m-r-10">Receipt</label>
                              <div class="flex-grow-1 text-end">
                                <label class="switch">
                                  <input type="checkbox" v-model="email_receipt" /><span
                                    class="switch-state"
                                  ></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-start">
                      <div class="mt-2 pt-2">
                        <div class="d-flex flex-column attachment-wrapper gap-2">
                          <template
                            v-for="(
                              send_attachment, send_attachment_index
                            ) in send_attachments_attached"
                            :key="`attachment-attached-index-${send_attachment_index}`"
                          >
                            <div
                              class="d-flex justify-content-between gap-2 attachment-item"
                            >
                              <div class="d-flex gap-1">
                                <span class="filename">{{ send_attachment.name }}</span>
                                <span
                                  >({{ $filters.filesize(send_attachment.size) }})</span
                                >
                              </div>

                              <button
                                class="btn-close btn-sm"
                                @click.stop="handleRemoveAttachment(send_attachment)"
                              ></button>
                            </div>
                          </template>

                          <template
                            v-for="(
                              send_attachment, send_attachment_index
                            ) in send_attachments"
                            :key="`attachment-index-${send_attachment_index}`"
                          >
                            <div
                              class="d-flex justify-content-between gap-2 attachment-item"
                            >
                              <div class="d-flex gap-1">
                                <span class="filename">{{ send_attachment.name }}</span>
                                <span
                                  >({{ $filters.filesize(send_attachment.size) }})</span
                                >
                              </div>

                              <button
                                class="btn-close btn-sm"
                                @click.stop="handleRemoveAttachment(send_attachment)"
                              ></button>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div class="send-btn d-inline-flex gap-2 justify-content-end">
                        <button
                          class="btn btn-secondary"
                          type="button"
                          @click="handleAttachFiles"
                        >
                          Attach files<i class="fa-solid fa-paperclip"></i>
                        </button>
                        <button class="btn btn-primary" type="button" @click="handleSend">
                          Send<i class="fa-solid fa-paper-plane"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-xxl-3 col-xl-3 box-col-3 p-0"
          style="position: fixed; right: 0px; width: 22%"
        >
          <div class="email-right-aside p-2">
            <div class="p-2 search-wrapper mb-2">
              <div class="d-flex align-items-center gap-2">
                <i class="search-bg iconly-Search icli"></i>
                <input
                  class="demo-input py-0 Typeahead-input form-control-plaintext w-100"
                  type="text"
                  placeholder="Search for last name, email address, and code."
                  title=""
                  @keyup="searchTerm"
                  v-model="keyword"
                />
                <template v-if="keyword != ''">
                  <span
                    @click.stop="keyword = ''"
                    class="cursor-pointer close-btn d-flex align-items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="3"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </span>
                </template>
              </div>
            </div>
            <template v-if="searched_email_easyreg.length > 0">
              <div class="card email-body email-read show gap-2 searched-wrapper">
                <div class="p-2">
                  <span class="text">Search Results</span>
                </div>
                <template
                  v-for="(email_easyreg_item, index) in searched_email_easyreg"
                  :key="`searched-email-easyreg-item-${index}`"
                >
                  <div
                    class="d-flex justify-content-between align-items-start p-2 assigned-wrapper"
                  >
                    <div class="d-flex flex-column">
                      <div class="d-flex gap-2">
                        <span class="assigned_name"
                          >{{ email_easyreg_item.firstname }}
                          {{ email_easyreg_item.lastname }}</span
                        >
                        <div class="badge badge-primary">Attendee</div>
                      </div>
                      <div class="d-flex gap-2">
                        <div
                          class="badge"
                          :class="getStatusColor(email_easyreg_item.status)"
                        >
                          {{ getStatus(email_easyreg_item.status) }}
                        </div>
                      </div>
                      <div class="assigned_event">
                        {{ email_easyreg_item.event_name }}
                      </div>
                      <div>{{ email_easyreg_item.client_abbreviation }}</div>
                    </div>
                    <div>
                      <span class="assigned_code d-flex flex-column gap-1">
                        <div>
                          <strong>{{ email_easyreg_item.code }}</strong>
                          <span @click.stop="handleCopyCode(email_easyreg_item.code)">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              style="width: 15px; height: 15px"
                              class="cursor-pointer"
                            >
                              <path
                                d="M224 0c-35.3 0-64 28.7-64 64V288c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H224zM64 160c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H288c35.3 0 64-28.7 64-64V384H288v64H64V224h64V160H64z"
                              />
                            </svg>
                          </span>
                        </div>

                        <div class="mt-0 d-flex justify-content-end">
                          <button
                            type="button"
                            @click.stop="handleAssign(email_easyreg_item)"
                            class="btn btn-md btn-light text-dark btn-receipt"
                          >
                            Assign
                          </button>
                        </div>
                      </span>
                    </div>
                  </div>
                </template>
              </div>
            </template>
            <div class="card email-body email-read show gap-2 mb-0">
              <template
                v-for="(email_easyreg_item, index) in email_easyreg"
                :key="`email-easyreg-item-${index}`"
              >
                <div
                  class="d-flex justify-content-between align-items-start p-2 assigned-wrapper"
                >
                  <div class="d-flex flex-column">
                    <div class="d-flex gap-2">
                      <span class="assigned_name">{{ email_easyreg_item.name }}</span>
                      <div class="badge badge-primary">Attendee</div>
                    </div>
                    <!-- <div class="d-flex gap-1">
                      <span class="assigned_name">
                        {{ email_easyreg_item.email }}
                      </span>
                      <span
                        @click.stop="handleCopyCode(email_easyreg_item.code)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          style="width: 15px; height: 15px"
                          class="cursor-pointer"
                        >
                          <path
                            d="M224 0c-35.3 0-64 28.7-64 64V288c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H224zM64 160c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H288c35.3 0 64-28.7 64-64V384H288v64H64V224h64V160H64z"
                          />
                        </svg>
                      </span>
                    </div> -->
                    <div class="d-flex gap-2">
                      <div
                        class="badge"
                        :class="getStatusColor(email_easyreg_item.status)"
                      >
                        {{ getStatus(email_easyreg_item.status) }}
                      </div>
                    </div>
                    <div class="assigned_event">
                      {{ email_easyreg_item.event_name }}
                    </div>
                    <div>{{ email_easyreg_item.client_abbreviation }}</div>
                    <template v-if="email_easyreg_item.status != 'W'">
                      <div class="mt-3">
                        <button
                          type="button"
                          class="btn btn-md btn-light text-dark btn-receipt"
                          @click.stop="
                            handleReceipt(
                              email_easyreg_item.code,
                              email_easyreg_item.client_code,
                              email_easyreg_item.event_code
                            )
                          "
                        >
                          Receipt
                        </button>
                      </div>
                    </template>
                  </div>
                  <div>
                    <span class="assigned_code d-flex gap-1">
                      <strong>{{ email_easyreg_item.code }}</strong>
                      <span @click.stop="handleCopyCode(email_easyreg_item.code)">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          style="width: 15px; height: 15px"
                          class="cursor-pointer"
                        >
                          <path
                            d="M224 0c-35.3 0-64 28.7-64 64V288c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H224zM64 160c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H288c35.3 0 64-28.7 64-64V384H288v64H64V224h64V160H64z"
                          />
                        </svg>
                      </span>
                    </span>
                  </div>
                </div>
              </template>
            </div>
            <div class="p-2 pb-0 article-wrapper">
              <span class="text">Search Articles</span>
            </div>
            <div class="p-2 search-wrapper">
              <div class="d-flex align-items-center gap-2">
                <i class="search-bg iconly-Search icli"></i>
                <input
                  class="demo-input py-0 Typeahead-input form-control-plaintext w-100"
                  type="text"
                  placeholder="Search for keywords or title"
                  title=""
                  @keyup="searchArticles"
                  v-model="keyword_for_article"
                />
                <template v-if="keyword_for_article != ''">
                  <span
                    @click.stop="keyword_for_article = ''"
                    class="cursor-pointer close-btn d-flex align-items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="3"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </span>
                </template>
              </div>
            </div>

            <template v-if="searched_articles.length > 0">
              <div class="mt-1 p-2">
                <span class="badge badge-success">
                  <h4>Articles</h4>
                </span>
              </div>
              <div
                class="card email-body email-read show gap-2 article-wrapper overflow-wrapper"
                style="height: 400px; overflow: scroll"
              >
                <template
                  v-for="(searched_article, index) in searched_articles"
                  :key="`searched-article-${index}`"
                >
                  <div
                    class="flex flex-column align-items-start p-2 article-searched-wrapper"
                  >
                    <div class="d-flex flex-column">
                      <div class="d-flex gap-2 justify-between-content article-headline">
                        <span class="assigned_name flex-1">{{
                          searched_article.article_headline
                        }}</span>
                        <button
                          type="button"
                          @click.stop="handleInsert(searched_article.article_id)"
                          class="btn btn-md btn-light text-dark btn-receipt"
                        >
                          Insert
                        </button>
                      </div>
                    </div>
                    <div>
                      <span class="article-content d-flex flex-column gap-1">
                        <div v-html="searched_article.article_text"></div>
                      </span>
                    </div>
                  </div>
                </template>
              </div>
            </template>

            <template v-if="searched_urls.length > 0">
              <div class="mt-1 p-2">
                <span class="badge badge-success">
                  <h4>Tiny URLs</h4>
                </span>
              </div>
              <div
                class="card email-body email-read show gap-2 article-wrapper overflow-url-wrapper"
              >
                <template
                  v-for="(searched_url, index) in searched_urls"
                  :key="`searched-url-${index}`"
                >
                  <div
                    class="flex flex-column align-items-start p-2 article-searched-wrapper"
                  >
                    <div class="d-flex flex-column">
                      <div class="d-flex gap-2 justify-between-content article-headline">
                        <span class="assigned_name flex-1">{{
                          searched_url.url_keywords
                        }}</span>
                        <button
                          type="button"
                          @click.stop="handleInsertURL(searched_url.url_id)"
                          class="btn btn-md btn-light text-dark btn-receipt mb-1"
                        >
                          Insert
                        </button>
                      </div>
                    </div>
                    <div>
                      <span class="article-content d-flex flex-column gap-1">
                        <div v-html="searched_url.url_short"></div>
                      </span>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <input type="file" accept="*" hidden ref="uploadRef" @change="uploadFile" />
  </div>
</template>
<style lang="scss" scoped>
.searched-wrapper {
  background: #ececec;
  .text {
    color: #7bcc00;
    font-weight: 800;
    font-size: 18px;
  }
}
.article-wrapper {
  background: none;
  .text {
    color: #7bcc00;
    font-weight: 800;
    font-size: 18px;
  }
  .assigned_name {
    font-size: 13px;
    font-weight: 800;
  }
}
.overflow-wrapper {
  height: 350px;
  padding: 2px;
  overflow-y: scroll;
}
.overflow-url-wrapper {
  height: 250px;
  padding: 2px;
  overflow-y: scroll;
}
.article-searched-wrapper {
  border-bottom: 3px solid #7bcc00;
  .article-headline {
    width: 100%;
    border-bottom: 1px solid #7bcc00;
    justify-content: space-between !important;
    align-items: start;
    .assigned_name {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 13px;
      font-weight: 800;
    }
  }
  .btn-receipt {
    border: 1px solid #7bcc00;
    float: right;
  }
  .article-content {
    height: 37px;
    font-weight: 300;
    letter-spacing: 0.3;
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      color: var(--font-color);
      font-weight: 400;
      line-height: 1.9;
    }
  }
}
.email-right-aside {
  background: #fff;
}
.search-wrapper {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 20px;
  .close-btn {
    svg {
      width: 18px;
      height: 18px;
    }
  }
}
.assigned-wrapper {
  border-bottom: 2px solid #7bcc00;
  .assigned_event {
    font-weight: 600;
  }
  .assigned_name {
    font-size: 17px;
    font-weight: 800;
  }
  .btn-receipt {
    border: 1px solid #7bcc00;
  }
  .assigned_code {
    font-size: 18px;
    font-weight: 800;
    align-items: end !important;
  }
}
.attachment-wrapper {
  max-width: 450px;
  .attachment-item {
    width: 100%;
    font-weight: 600;
    .filename {
      flex: 1;
      color: blue;
      max-width: 320px;
      display: inline-block;
      overflow: hidden;
      padding: 3px 0;
      text-overflow: ellipsis;
      vertical-align: bottom;
      white-space: nowrap;
    }
    background: #ececec;
    padding: 5px 20px;
  }
}
.btn-reply {
  display: flex;
  border: 1px solid #730000;
  &:hover {
    border: 1px solid #730000;
    font-weight: bold;
  }
  &:focus {
    border: 1px solid #730000;
    font-weight: bold;
  }
  &:before {
    background-image: url(//ssl.gstatic.com/ui/v1/icons/mail/gm3/1x/reply_baseline_nv700_20dp.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    opacity: 1;
    height: 20px;
    width: 20px;
    margin-right: 8px;
    content: "";
  }
  &.active {
    background-color: #b2b1bd !important;
  }
}
.btn-reply-all {
  display: flex;
  border: 1px solid #730000;
  &:hover {
    border: 1px solid #730000;
    font-weight: bold;
  }
  &:focus {
    border: 1px solid #730000;
    font-weight: bold;
  }
  &:before {
    background-image: url(//ssl.gstatic.com/ui/v1/icons/mail/gm3/1x/reply_all_baseline_nv700_20dp.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    opacity: 1;
    height: 20px;
    width: 20px;
    margin-right: 8px;
    content: "";
  }
  &.active {
    background-color: #b2b1bd !important;
  }
}
.btn-forward {
  display: flex;
  border: 1px solid #730000;
  &:hover {
    border: 1px solid #730000;
    font-weight: bold;
  }
  &:focus {
    border: 1px solid #730000;
    font-weight: bold;
  }
  &:before {
    background-image: url(//ssl.gstatic.com/ui/v1/icons/mail/gm3/2x/forward_baseline_nv700_20dp.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    opacity: 1;
    height: 20px;
    width: 20px;
    margin-right: 8px;
    content: "";
  }
  &.active {
    background-color: #b2b1bd !important;
  }
}

.toolbar-box {
  padding: 3px !important;
  border: 1px solid #730000;
}
.mail-subcontent::v-deep {
  height: 540px;
  iframe {
    html {
      body {
        font-family: sans-serif;
      }
    }
  }
}
#editor-container::v-deep {
  height: 450px !important;
}
.flex-1 {
  flex: 1;
}
.editor-wrapper {
  height: 450px !important;
}
ul {
  li {
    padding: unset !important;
    display: block !important;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid var(--light-gray);
  }
}
</style>
<script lang="ts" setup>
import Swal from "sweetalert2";
import debounce from "lodash.debounce";
import VueMultiselect from "vue-multiselect";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import {
  defineEmits,
  reactive,
  defineAsyncComponent,
  watch,
  ref,
  onMounted,
  nextTick,
  useTemplateRef,
  onUnmounted,
  onBeforeUnmount,
} from "vue";
import { mail } from "@/core/data/emails";
import { toast } from "vue3-toastify";
import {
  AssignEmailBySearched,
  GetEmailSync,
  GetInitDesign,
  GetSearchByKeyword,
  GetSearchSystemByKeyword,
  ReturnDesignJsonByAddTemplate,
  SaveAutoJson,
  SaveDraftEmailValue,
  SendEmailUsingNylas,
  SetEmailCategory,
} from "@/services/ApiCore";
import TopolPlugin from "@topol.io/editor";
let to_subject = ref<string>("");
let email_subject = ref<string>("");
let email_to_name = ref<string>("");
let email_to_email = ref<string>("");
let email_from_name = ref<string>("");
let email_from_email = ref<string>("");
let email_body = ref<string>("");
let email_reply_email = ref<string>("");
let email_reply_name = ref<string>("");
let email_easyreg = ref<any>([]);
let searched_email_easyreg = ref<any>([]);
let searched_articles = ref<any>([]);
let searched_urls = ref<any>([]);

let email_cc_emails = ref<any>([]);
let email_bcc_emails = ref<any>([]);
let send_attachments = ref<any>([]);
let send_attachments_attached = ref<any>([]);

let send_from_email = ref<any>("martin@easyreg.ca");
let to_searched_result = ref<any>([]);
let cc_searched_result = ref<any>([]);
let bcc_searched_result = ref<any>([]);
let send_to_emails = ref<any>([]);
let send_cc_emails = ref<any>([]);
let send_bcc_emails = ref<any>([]);
let email_date = ref<string>("");
let currentJson = ref<string>("");
let previousJson = "";
let currentHtml = ref<string>("");
let reply_section = ref<boolean>(false);
let forwarded_email = ref<boolean>(false);
let keyword = ref<string>("");
let keyword_for_article = ref<string>("");
let email_attachments = ref<any>([]);
let email_closed = ref<boolean>(true);
let email_receipt = ref<boolean>(false);
let isLoading = ref<boolean>(false);
let isToLoading = ref<boolean>(false);
let isCCLoading = ref<boolean>(false);
let isBCCLoading = ref<boolean>(false);
let topolPluginInit = ref<boolean>(false);
const hasUnsavedChanges = ref<boolean>(false);
let emit = defineEmits(["closed", "sent", "trashed"]);
let sendingEmail = ref<boolean>(false);
import { useCommonStore } from "@/store/common";
import { useRoute, useRouter } from "vue-router";
import { GetArticles } from "@/services/ApiArticles";
let save_idx = 0;
let autosave_interval = -1;
let router = useRouter();
const route = useRoute();
const commonStore = useCommonStore();
function trash() {
  (async () => {
    try {
      await SetEmailCategory(route.params.emails_id.toString(), "TRASH");
      router.push(`/app/emails/${route.params.tab}`);
    } catch (e) {
      console.log("trash error", e);
    }
  })();
}
function back() {
  router.push(`/app/emails/${route.params.tab}`);
}
const emailBodyRef = useTemplateRef("emailBodyRef");
const uploadRef = useTemplateRef("uploadRef");
function downloadAttachment(
  attachments_filename: string,
  attachments_size: any,
  attachments_content_type: string,
  attachments_id: string
) {
  try {
    let file = `https://api.us.nylas.com/v3/grants/${process.env.NYLAS_GRANT_ID}/attachments/${attachments_id}/download?message_id=${route.params.emails_id}`;
    let headers = new Headers();
    headers.append("Authorization", `Bearer ${process.env.NYLAS_API_KEY}`);

    fetch(file, { headers })
      .then((response) => response.blob())
      .then((blobby) => {
        var reader = new FileReader();
        // this function is triggered once a call to readAsDataURL returns
        reader.onload = function (event: any) {
          let file = event.target.result;
          send_attachments_attached.value = [
            ...send_attachments_attached.value,
            {
              handleId: generateId(20),
              file,
              name: attachments_filename,
              size: attachments_size,
              type: attachments_content_type,
              attachments_id: attachments_id,
              uploaded: "A",
            },
          ];
        };
        // trigger the read from the reader...
        reader.readAsDataURL(blobby);
      });
  } catch (e) {
    console.log("handleDownload error", e);
  }
}
function handleDownload(attachments_name: string, attachments_id: string) {
  (async () => {
    try {
      isLoading.value = true;
      console.log("attachments_id", attachments_id);

      let anchor = document.createElement("a");
      document.body.appendChild(anchor);

      let file = `https://api.us.nylas.com/v3/grants/${process.env.NYLAS_GRANT_ID}/attachments/${attachments_id}/download?message_id=${route.params.emails_id}`;
      let headers = new Headers();
      headers.append("Authorization", `Bearer ${process.env.NYLAS_API_KEY}`);

      fetch(file, { headers })
        .then((response) => response.blob())
        .then((blobby) => {
          let objectUrl = window.URL.createObjectURL(blobby);

          anchor.href = objectUrl;
          anchor.download = `${attachments_name}`;
          anchor.click();
          isLoading.value = false;
          window.URL.revokeObjectURL(objectUrl);
        });

      isLoading.value = false;
    } catch (e) {
      console.log("handleDownload error", e);
    }
  })();
}
function handleSend() {
  (async () => {
    if (autosave_interval) {
      clearInterval(autosave_interval);
    }
    sendingEmail.value = true;
    TopolPlugin.save();
  })();
}
function loadEditor() {
  if (topolPluginInit.value) {
    TopolPlugin.destroy();
  }
  const TOPOL_OPTIONS = {
    id: "#editor-container",
    // enableAutosaves: true,
    // api: {
    //   // Api endpoints needed for autosaves
    //   AUTOSAVE: `https://crm-api.easyreg.cloud/api/autosave/${route.params.emails_id.toString()}`,
    // },
    // autosaveInterval: 3,
    authorize: {
      apiKey: process.env.TOPOL_API_KEY,
      userId: 1,
    },
    disableAlerts: true,
    light: true,
    premadeBlocks: [
      {
        name: "Online Attendee Site",
        blocks: [
          {
            img:
              "https://2023-easyreg-prod.s3.ca-central-1.amazonaws.com/attachments/online_attendee_site_premade_block.png", // Image url, for best experience use width > 330 px
            name: "Online Attendee Site Block", // Or name if not image available
            // MJML JSON
            definition: [
              {
                tagName: "mj-section",
                attributes: {
                  "full-width": "600px",
                  padding: "10px 0px 10px 0px",
                  "mj-class": "section",
                },
                type: null,
                children: [
                  {
                    tagName: "mj-column",
                    attributes: {
                      width: "100%",
                      "vertical-align": "top",
                    },
                    children: [
                      {
                        tagName: "mj-text",
                        content:
                          '<p>You can access the Online Attendee Site using the following link:<br>###attendee.accesslink###</p>\n          <p style="font-weight: bold; margin-bottom: 10px; margin-top: 10px;">OR</p>\n          <ul>\n              <li>Go to https://online.ourconference.events</li>\n              <li>Enter ###attendee.email### under Email Address</li>\n              <li>Enter ###attendee.code### under Code</li>\n              <li>Click Login</li>\n          </ul>',
                        attributes: {
                          padding: "10px 10px",
                          align: "left",
                          "line-height": 1.3,
                          containerWidth: 600,
                        },
                        uid: "H1lqIiX4lm",
                      },
                    ],
                    uid: "SJ3I0XVx7",
                  },
                ],
                layout: 1,
                backgroundColor: "",
                backgroundImage: "",
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
                uid: "rkqIjQNe7",
              },
            ],
          },
        ],
      },
    ],
    customBlocks: [],
    callbacks: {
      onInit(json: any, html: any) {
        topolPluginInit.value = true;
        autosave_interval = setInterval(() => {
          TopolPlugin && TopolPlugin.save();
        }, 10000);
      },
      async onSave(json: any, html: any) {
        console.log("onSave");
        currentJson.value = json;
        currentHtml.value = html;

        if (sendingEmail.value) {
          sendingEmail.value = false;
          try {
            if (send_to_emails.value.length == 0) {
              sendingEmail.value = false;
              return;
            }

            if (send_from_email.value == "") {
              sendingEmail.value = false;
              return;
            }
            if (to_subject.value == "") {
              sendingEmail.value = false;
              return;
            }
            console.log("to_subject", to_subject.value);
            console.log("email_closed", email_closed.value);
            isLoading.value = true;

            let forward_email = forwarded_email.value ? "Y" : "N";
            const { result } = await SendEmailUsingNylas(
              route.params.emails_id.toString(),
              send_from_email.value,
              JSON.stringify(send_to_emails.value),
              JSON.stringify(send_cc_emails.value),
              JSON.stringify(send_bcc_emails.value),
              to_subject.value,
              currentHtml.value,
              send_attachments.value,
              send_attachments_attached.value,
              forward_email,
              email_closed.value ? "Y" : "N",
              email_receipt.value ? "Y" : "N"
            );
            isLoading.value = false;
            commonStore.toggleSent(result);
            sendingEmail.value = false;
            autosave_interval = setInterval(() => {
              TopolPlugin && TopolPlugin.save();
            }, 10000);
            if (result == "success") {
              router.push("/app/emails/sent");
            } else {
            }
          } catch (e) {
            sendingEmail.value = false;
            autosave_interval = setInterval(() => {
              TopolPlugin && TopolPlugin.save();
            }, 10000);
            console.log("handleSend error", e);
          }
        } else {
          if (
            JSON.stringify(previousJson).length != JSON.stringify(json).length &&
            save_idx > 0
          ) {
            await SaveAutoJson(route.params.emails_id.toString(), JSON.stringify(json));
            previousJson = { ...json };
          }
          if (save_idx == 0) {
            previousJson = { ...json };
          }
          save_idx++;
        }
      },
      onLoaded(json: any, html: any) {
        TopolPlugin.save();
        // hide the loader
      },
    },
  };
  TopolPlugin.init(TOPOL_OPTIONS);
}
function loadDesign(json: any) {
  if (json) {
    TopolPlugin.load(json);
  }
}
function handleForward() {
  (async () => {
    reply_section.value = false;
    forwarded_email.value = true;
    send_to_emails.value = [];
    send_cc_emails.value = [];
    send_bcc_emails.value = [];

    let sendPromise = [];
    for (let idx = 0; idx < email_attachments.value.length; idx++) {
      sendPromise.push(
        downloadAttachment(
          email_attachments.value[idx].attachments_filename,
          email_attachments.value[idx].attachments_size,
          email_attachments.value[idx].attachments_content_type,
          email_attachments.value[idx].attachments_id
        )
      );
    }
    try {
      await Promise.allSettled(sendPromise).catch(function (err) {
        console.log("sendPromise A error", err); // some coding error in handling happened
      });
    } catch (error) {
      console.log("sendPromise B error", error);
    }
    nextTick(async () => {
      await initEditor();
    });
  })();
}

function initEditor() {
  (async () => {
    try {
      loadEditor();
      const {
        json,
        html,
        emails_subject,
        emails_from,
        emails_to_name,
        emails_to_email,
        cc_emails,
        bcc_emails,
        emails_definition,
      } = await GetInitDesign(
        route.params.emails_id.toString(),
        forwarded_email.value ? "Y" : "N"
      );
      if (emails_definition != "") {
        to_subject.value = emails_subject;
        currentJson.value = JSON.parse(emails_definition);
        previousJson = JSON.parse(emails_definition);
        currentHtml.value = html;

        send_from_email.value = emails_from;
        loadDesign(emails_definition);

        if (emails_to_email != "" && emails_to_name != "") {
          send_to_emails.value = [
            ...send_to_emails.value,
            {
              firstname: emails_to_name,
              lastname: "",
              email: emails_to_email,
            },
          ];
        }

        send_cc_emails.value = cc_emails.map((x: any) => {
          return {
            firstname: x.cc_email,
            lastname: "",
            email: x.cc_email,
          };
        });
        send_bcc_emails.value = bcc_emails.map((x: any) => {
          return {
            firstname: x.bcc_email,
            lastname: "",
            email: x.bcc_email,
          };
        });
      } else {
        currentJson.value = json;
        previousJson = json;
        currentHtml.value = html;
        loadDesign(json);
      }
    } catch (e) {
      console.log("onMounted error", e);
    }
  })();
}
function generateId(len: any) {
  var arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr).join("");
}
async function loadFile(files: any) {
  const filePromises = Array.from(files).map((file: any) => {
    // Return a promise per file
    return new Promise((resolve, reject) => {
      let response = {};
      const reader = new FileReader();
      reader.onload = async () => {
        try {
          response = {
            handleId: generateId(20),
            pathname: file.name,
            name: file.name,
            size: file.size,
            type: file.type,
            image: null,
            file: file,
            attachments_id: "",
            uploaded: "B",
          };
          // Resolve the promise with the response value
          resolve(response);
        } catch (err) {
          reject(err);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  });
  const fileInfos = await Promise.all(filePromises);
  console.log("fileInfos", fileInfos);
  return fileInfos;
}
async function uploadFile(e: any) {
  if (e.target.files.length === 0) return;
  console.log("e.target.files", e.target.files);
  const result = await loadFile(e.target.files);
  console.log("result", result);
  send_attachments.value = [...send_attachments.value, ...result];
}
function handleRemoveAttachment(file: any) {
  send_attachments.value = send_attachments.value.filter(
    (x: any) => x.handleId != file.handleId
  );
}
function handleAttachFiles() {
  if (uploadRef.value) {
    uploadRef.value.click();
  }
}
function handleCopyCode(value: string) {
  const el = document.createElement("textarea");
  el.value = value;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);

  toast.success("Copied! ", {
    position: "top-right",
    autoClose: 2000,
  });
}
async function searchTerm(e: any) {
  if (e.which == 13) {
    if (keyword.value != "") {
      searched_email_easyreg.value = await GetSearchSystemByKeyword(keyword.value);
    }
    e.preventDefault();
  }
}
async function searchArticles(e: any) {
  if (e.which == 13) {
    if (keyword_for_article.value != "") {
      const { rows, urls } = await GetArticles("keyword", keyword_for_article.value, {});
      searched_articles.value = rows;
      searched_urls.value = urls;
    }
    e.preventDefault();
  }
}
async function handleReceipt(code: string, client: string, event: string) {
  let anchor = document.createElement("a");
  document.body.appendChild(anchor);
  let file = `https://api.easyreg2023.ca/api/downloadCRMReceiptByCode/${code}/${client}/${event}`;

  let headers = new Headers();
  fetch(file, { headers })
    .then((response) => response.blob())
    .then((blobby) => {
      let objectUrl = window.URL.createObjectURL(blobby);

      anchor.href = objectUrl;
      anchor.download = `Receipt-${code}.pdf`;
      anchor.click();
      window.URL.revokeObjectURL(objectUrl);
      toast.success("Done! ", {
        position: "top-right",
        autoClose: 2000,
      });
    });
}
async function handleAssign(item: any) {
  await AssignEmailBySearched(route.params.emails_id.toString(), JSON.stringify(item));
  searched_email_easyreg.value = [];
  keyword.value = "";
}
function getStatus(status: string) {
  if (status == "A") {
    return "Active";
  } else if (status == "D") {
    return "Deleted";
  } else if (status == "C") {
    return "Cancelled";
  } else {
    return "In Progress";
  }
}
function getStatusColor(status: string) {
  if (status == "A") {
    return "badge-primary";
  } else if (status == "D") {
    return "badge-danger";
  } else if (status == "C") {
    return "badge-danger";
  } else {
    return "badge-warning";
  }
}
function handleInsert(article_id: any) {
  (async () => {
    try {
      const { json } = await ReturnDesignJsonByAddTemplate(
        JSON.stringify(currentJson.value),
        article_id,
        "article"
      );
      loadDesign(json);
    } catch (e) {
      console.log("onMounted error", e);
    }
  })();
}

function handleInsertURL(url_id: any) {
  (async () => {
    try {
      const { json } = await ReturnDesignJsonByAddTemplate(
        JSON.stringify(currentJson.value),
        url_id,
        "url"
      );
      loadDesign(json);
    } catch (e) {
      console.log("onMounted error", e);
    }
  })();
}
const addNewToEmail = debounce(async (email: any, type: string) => {
  const new_tag = {
    firstname: email,
    lastname: "",
    email: email,
  };
  if (type == "to") {
    send_to_emails.value.push(new_tag);
    await SaveDraftEmailValue(
      route.params.emails_id.toString(),
      JSON.stringify(send_to_emails.value),
      "to"
    );
  } else if (type == "cc") {
    send_cc_emails.value.push(new_tag);
    await SaveDraftEmailValue(
      route.params.emails_id.toString(),
      JSON.stringify(send_cc_emails.value),
      "cc"
    );
  } else if (type == "bcc") {
    send_bcc_emails.value.push(new_tag);
    await SaveDraftEmailValue(
      route.params.emails_id.toString(),
      JSON.stringify(send_bcc_emails.value),
      "bcc"
    );
  }
}, 500);
const searchBCC = debounce(async (keyword: any) => {
  if (keyword.length < 5) {
    return;
  }
  isBCCLoading.value = false;
  bcc_searched_result.value = await GetSearchByKeyword(keyword);
  isBCCLoading.value = false;
}, 500);
const searchCC = debounce(async (keyword: any) => {
  if (keyword.length < 5) {
    return;
  }
  isCCLoading.value = true;
  cc_searched_result.value = await GetSearchByKeyword(keyword);
  isCCLoading.value = false;
}, 500);
const search = debounce(async (keyword: any) => {
  if (keyword.length < 5) {
    return;
  }
  isToLoading.value = true;
  to_searched_result.value = await GetSearchByKeyword(keyword);
  isToLoading.value = false;
}, 500);
const handleChangeTo = debounce(async () => {
  if (send_to_emails.value.length > 0) {
    let send_to_emails_arr = send_to_emails.value.map((x) => {
      return {
        firstname: x.firstname,
        lastname: x.lastname,
        email: x.email,
      };
    });
    await SaveDraftEmailValue(
      route.params.emails_id.toString(),
      JSON.stringify(send_to_emails_arr),
      "to"
    );
  }
}, 500);
const handleChangeCC = debounce(async () => {
  if (send_cc_emails.value.length > 0) {
    let send_cc_emails_arr = send_cc_emails.value.map((x) => {
      return {
        firstname: x.firstname,
        lastname: x.lastname,
        email: x.email,
      };
    });
    await SaveDraftEmailValue(
      route.params.emails_id.toString(),
      JSON.stringify(send_cc_emails_arr),
      "cc"
    );
  }
}, 500);
const handleChangeBCC = debounce(async () => {
  if (send_bcc_emails.value.length > 0) {
    let send_bcc_emails_arr = send_bcc_emails.value.map((x) => {
      return {
        firstname: x.firstname,
        lastname: x.lastname,
        email: x.email,
      };
    });
    await SaveDraftEmailValue(
      route.params.emails_id.toString(),
      JSON.stringify(send_bcc_emails_arr),
      "bcc"
    );
  }
}, 500);
const pickSendFromEmail = debounce(async (email: string) => {
  send_from_email.value = email;
  await SaveDraftEmailValue(route.params.emails_id.toString(), email, "from");
}, 500);
const debouncedInput = debounce(async (event: Event) => {
  const target = event.target as HTMLInputElement;
  to_subject.value = target.value;
  await SaveDraftEmailValue(
    route.params.emails_id.toString(),
    to_subject.value,
    "subject"
  );
}, 500);
onUnmounted(() => {
  if (topolPluginInit.value) {
    TopolPlugin.destroy();
  }
});
onMounted(() => {
  (async () => {
    reply_section.value = false;
    forwarded_email.value = false;
    sendingEmail.value = false;
    keyword.value = "";
    keyword_for_article.value = "";
    searched_articles.value = [];
    send_from_email.value = "martin@easyreg.ca";
    searched_email_easyreg.value = [];
    email_easyreg.value = [];
    send_attachments.value = [];
    send_attachments_attached.value = [];
    send_to_emails.value = [];
    send_cc_emails.value = [];
    send_bcc_emails.value = [];
    nextTick(async () => {
      await initEditor();
    });
  })();
});
</script>
