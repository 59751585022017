import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "email-wrap email-main-wrapper" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-xxl-3 col-xl-4 col-md-5 box-col-5" }
const _hoisted_5 = { class: "left-sidebar-wrapper card" }
const _hoisted_6 = { class: "left-sidebar-chat" }
const _hoisted_7 = { class: "input-group" }
const _hoisted_8 = { class: "input-group-text" }
const _hoisted_9 = { class: "advance-options" }
const _hoisted_10 = {
  class: "tab-content",
  id: "chat-options-tabContent"
}
const _hoisted_11 = {
  class: "tab-pane fade show active",
  id: "chats",
  role: "tabpanel",
  "aria-labelledby": "chats-tab"
}
const _hoisted_12 = { class: "chats-user" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "chat-time flex-1" }
const _hoisted_15 = { class: "active-profile" }
const _hoisted_16 = ["src"]
const _hoisted_17 = { class: "flex-1" }
const _hoisted_18 = { class: "main break-all line-clamp-1" }

import { chat } from "@/core/data/chat";
import { getImages } from "@/composables/common/getImages";
import { ref, defineAsyncComponent, computed, onMounted, useTemplateRef } from "vue";
import { useChatStore } from "@/store/chat";
import { storeToRefs } from "pinia";
import { GetSMSContact } from "@/services/ApiSMS";

export default /*@__PURE__*/_defineComponent({
  __name: 'IndexSMS',
  setup(__props) {

const chatRightRef = useTemplateRef("chatRightRef");

const ChatRight = defineAsyncComponent(
  () => import("@/components/theme/chat/private/ChatRight.vue")
);
const FrontPlugin = defineAsyncComponent(
  () => import("@/components/theme/chat/private/FrontPlugin.vue")
);
const { currentChat } = storeToRefs(useChatStore());
let currenat = ref(currentChat.value);
const store = useChatStore();
const search = ref<string>("");
const serchUser = store.serchUser;

let users = ref<any>([]);

function getImgUrl(path: string) {
  return require("@/assets/images/" + path);
}
function setActiveUser(user: any) {
  //   store.setActiveuser(user);
  users.value = users.value.map((x) => {
    if (x.number == user.number) {
      return {
        ...x,
        active: true,
      };
    } else {
      return { ...x, active: false };
    }
  });
  const { number } = user;
  if (chatRightRef.value) {
    chatRightRef.value.loadChat(number);
  }
}
const handleOnAddedChat = async (props) => {
  const { number, chat } = props;
  users.value = users.value.map((x: any) => {
    if (x.number == number) {
      return {
        ...x,
        last_message: chat,
      };
    } else {
      return { ...x };
    }
  });
};
const loadContact = async () => {
  users.value = await GetSMSContact();
};
onMounted(() => {
  loadContact();
});

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_vue_feather = _resolveComponent("vue-feather")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "SMS",
      mains: "SMS",
      title: "SMS"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", _hoisted_8, [
                    _createVNode(_component_vue_feather, {
                      class: "search-icon text-gray",
                      type: "search"
                    })
                  ]),
                  _withDirectives(_createElementVNode("input", {
                    class: "form-control",
                    type: "text",
                    placeholder: "Search here..",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event)),
                    onKeyup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setSearchUsers && _ctx.setSearchUsers(...args)))
                  }, null, 544), [
                    [_vModelText, search.value]
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _cache[3] || (_cache[3] = _createElementVNode("ul", {
                  class: "nav border-tab",
                  id: "chat-options-tab",
                  role: "tablist"
                }, [
                  _createElementVNode("li", { class: "nav-item" }, [
                    _createElementVNode("a", {
                      class: "nav-link active",
                      id: "chats-tab",
                      "data-bs-toggle": "tab",
                      href: "#chats",
                      role: "tab",
                      "aria-controls": "chats",
                      "aria-selected": "true"
                    }, "Chats")
                  ])
                ], -1)),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "common-space" }, [
                      _createElementVNode("p", null, "Recent chats"),
                      _createElementVNode("div", { class: "header-top" }, [
                        _createElementVNode("a", {
                          class: "btn badge-light-primary f-w-500",
                          href: "#!"
                        }, [
                          _createElementVNode("i", { class: "fa-solid fa-plus" })
                        ])
                      ])
                    ], -1)),
                    _createElementVNode("ul", _hoisted_12, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(users), (user, index) => {
                        return (_openBlock(), _createElementBlock("li", {
                          class: _normalizeClass(["common-space items-start", user.active ? 'chat-user-active' : '']),
                          key: index,
                          onClick: ($event: any) => (setActiveUser(user))
                        }, [
                          _createElementVNode("div", _hoisted_14, [
                            _createElementVNode("div", _hoisted_15, [
                              _createElementVNode("img", {
                                class: "img-fluid rounded-circle",
                                src: _unref(getImages)('user/1.jpg'),
                                alt: "user"
                              }, null, 8, _hoisted_16)
                            ]),
                            _createElementVNode("div", _hoisted_17, [
                              _createElementVNode("span", null, _toDisplayString(user.name), 1),
                              _createElementVNode("p", _hoisted_18, _toDisplayString(user.last_message), 1)
                            ])
                          ]),
                          _createElementVNode("div", null, [
                            _createElementVNode("p", null, _toDisplayString(user.last_message_time), 1)
                          ])
                        ], 10, _hoisted_13))
                      }), 128))
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createVNode(_unref(ChatRight), {
            ref_key: "chatRightRef",
            ref: chatRightRef,
            onOnAddedChat: handleOnAddedChat
          }, null, 512),
          _createVNode(_unref(FrontPlugin))
        ])
      ])
    ])
  ], 64))
}
}

})