<template>
  <Breadcrumbs main="SMS" mains="SMS" title="SMS" />
  <div class="container-fluid">
    <div class="email-wrap email-main-wrapper">
      <div class="row">
        <div class="col-xxl-3 col-xl-4 col-md-5 box-col-5">
          <div class="left-sidebar-wrapper card">
            <div class="left-sidebar-chat">
              <div class="input-group">
                <span class="input-group-text"
                  ><vue-feather class="search-icon text-gray" type="search"></vue-feather>
                </span>
                <input
                  class="form-control"
                  type="text"
                  placeholder="Search here.."
                  v-model="search"
                  v-on:keyup="setSearchUsers"
                />
              </div>
            </div>
            <div class="advance-options">
              <ul class="nav border-tab" id="chat-options-tab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="chats-tab"
                    data-bs-toggle="tab"
                    href="#chats"
                    role="tab"
                    aria-controls="chats"
                    aria-selected="true"
                    >Chats</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="chat-options-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="chats"
                  role="tabpanel"
                  aria-labelledby="chats-tab"
                >
                  <div class="common-space">
                    <p>Recent chats</p>
                    <div class="header-top">
                      <a class="btn badge-light-primary f-w-500" href="#!"
                        ><i class="fa-solid fa-plus"></i
                      ></a>
                    </div>
                  </div>
                  <ul class="chats-user">
                    <li
                      class="common-space items-start"
                      v-for="(user, index) in users"
                      :key="index"
                      :class="user.active ? 'chat-user-active' : ''"
                      @click="setActiveUser(user)"
                    >
                      <div class="chat-time flex-1">
                        <div class="active-profile">
                          <img
                            class="img-fluid rounded-circle"
                            :src="getImages('user/1.jpg')"
                            alt="user"
                          />
                          <!-- <div class="status" :class="item.StatusClass"></div> -->
                        </div>
                        <div class="flex-1">
                          <span>{{ user.name }}</span>
                          <p class="main break-all line-clamp-1">
                            {{ user.last_message }}
                          </p>
                        </div>
                      </div>
                      <div>
                        <p>{{ user.last_message_time }}</p>
                        <!-- <div class="badge badge-light-success" v-if="item.badge">
                          {{ item.badge }}
                        </div> -->
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ChatRight ref="chatRightRef" @on-added-chat="handleOnAddedChat" />
        <FrontPlugin />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.items-start {
  align-items: start !important;
}
.chat-user-active {
  background-color: rgba(204, 204, 204, 0.2) !important;
}
</style>

<script lang="ts" setup>
import { chat } from "@/core/data/chat";
import { getImages } from "@/composables/common/getImages";
import { ref, defineAsyncComponent, computed, onMounted, useTemplateRef } from "vue";
import { useChatStore } from "@/store/chat";
import { storeToRefs } from "pinia";
import { GetSMSContact } from "@/services/ApiSMS";
const chatRightRef = useTemplateRef("chatRightRef");

const ChatRight = defineAsyncComponent(
  () => import("@/components/theme/chat/private/ChatRight.vue")
);
const FrontPlugin = defineAsyncComponent(
  () => import("@/components/theme/chat/private/FrontPlugin.vue")
);
const { currentChat } = storeToRefs(useChatStore());
let currenat = ref(currentChat.value);
const store = useChatStore();
const search = ref<string>("");
const serchUser = store.serchUser;

let users = ref<any>([]);

function getImgUrl(path: string) {
  return require("@/assets/images/" + path);
}
function setActiveUser(user: any) {
  //   store.setActiveuser(user);
  users.value = users.value.map((x) => {
    if (x.number == user.number) {
      return {
        ...x,
        active: true,
      };
    } else {
      return { ...x, active: false };
    }
  });
  const { number } = user;
  if (chatRightRef.value) {
    chatRightRef.value.loadChat(number);
  }
}
const handleOnAddedChat = async (props) => {
  const { number, chat } = props;
  users.value = users.value.map((x: any) => {
    if (x.number == number) {
      return {
        ...x,
        last_message: chat,
      };
    } else {
      return { ...x };
    }
  });
};
const loadContact = async () => {
  users.value = await GetSMSContact();
};
onMounted(() => {
  loadContact();
});
</script>
