import ApiInstance from "./ApiBase";
export const GetSMSContact = async (): Promise<any> => {
    const res = await ApiInstance.get(`/sms-contact`);
    return res?.data;
};

export const GetSMSMessage = async (number: string): Promise<any> => {
    const res = await ApiInstance.get(`/sms-message/${number}`);
    return res?.data;
};



export const AddSMSMessage = async (number: string, message: string): Promise<any> => {
    const data = new FormData();
    data.append("number", number);
    data.append("message", message);
    const res = await ApiInstance.post(`/add-sms-message`, data);
    return res?.data;
};

export const GetInformationByTargetSearch = async (keyword: string): Promise<any> => {
    const res = await ApiInstance.get(`https://services.easyreg.ca/api/get-information-by-target-search/${keyword}`);
    return res?.data;
};

